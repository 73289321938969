import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from '../util/IntlMessages';
import logo from '../assets/coffeepass_web_logo_interior.png';
import {
    hideMessage,
    showAuthLoader,
    userSignUp,
} from '../actions';
import { shopSignupAction } from '../actions';
import ValidateEmail from '../component/dashboard/util/ValidateEmail';
import { MixpanelTrack, MixpanelEmail, MixpanelIdentify } from "../util/MixpanelHelper";

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: ''
        }
        this.formRef = React.createRef();
    }
    componentDidMount() {
        MixpanelTrack("Visited SignUp Page");
        if(this.formRef !== undefined) {
            console.log(this.formRef)
        }
    }
    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }

        if (this.props.shop != null && this.props.shop.shopId !== null && this.props.shop.shopId != "") {
            this.props.history.push('/onboarding');

        }
    }

    checkSignupValid() {
        if(this.state.email === '' || this.state.password === '' || this.state.name === '') {
            NotificationManager.error("All fields must be filled", "Error", 6000)
            return false
        }
        const validateEmail = new ValidateEmail()
        if(!validateEmail.isValid(this.state.email)) {
            NotificationManager.error("Email is not valid", "Error", 6000)
            return false
        }
        return true
    }

    render() {
        const {
            name,
            email,
            password
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src={logo} className="appLogo" alt="logo" />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1>Sign Up</h1>
                        </div>

                        <div className="mb-4">
                            <h2>Create A CoffeePass Shop Account</h2>
                        </div>

                        <div className="app-login-form">
                            <form method="post" ref={this.formRef} action="/">
                                <TextField
                                    type="text"
                                    label="Shop Name"
                                    onChange={(event) => this.setState({name: event.target.value})}
                                    fullWidth
                                    defaultValue={name}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="email"
                                    onChange={(event) => this.setState({email: event.target.value})}
                                    label='Shop Login Email'
                                    fullWidth
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({password: event.target.value})}
                                    label={"Shop Login Password"}
                                    fullWidth
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button variant="raised" onClick={() => {
                                        if(this.checkSignupValid()) {
                                            this.props.showAuthLoader()
                                            this.props.shopSignupAction({
                                                name: name,
                                                email: email,
                                                password: password
                                            }, (resp) => {
                                                console.log("onsuccess " + resp)
                                                if(resp.val == 'True') {
                                                    MixpanelIdentify(email)
                                                    MixpanelEmail(email)
                                                    MixpanelTrack('Successfully Signed Up')
                                                } else {
                                                    NotificationManager.error("Invalid Email or Password", 6000)
                                                }
                                            }, (error) => {
                                                NotificationManager.error("Error connecting to server. If you would, let us know the issue by emailing us at support@coffeepass.io. Thanks!", 6000)
                                            })
                                        }
                                    }} color="primary" style={{backgroundColor: "#78A852"}}>
                                        Create Account
                                    </Button>
                                    <Link className="anchor-cp" to="/signin">
                                        I already have an account
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {loader, alertMessage, showMessage, authUser, shop} = state.auth;
    return {loader, alertMessage, showMessage, authUser, shop}
};

export default connect(mapStateToProps, {
    userSignUp,
    hideMessage,
    showAuthLoader,
    shopSignupAction
})(SignUp);
