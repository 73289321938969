import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import SaveSureDialog from './SaveSureDialog';
import { updateShopInfoAction, getShopInfoAction } from '../../../actions/ShopInfo';
import { connect } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { states } from './states';
import SaveStepButton from '../../../containers/ShopOnboarding/SaveStepButton';
import PopoverItem from './PopoverItem'
import { TaxMask } from './MiscFields';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import selectors from '../../../reducers/selectors';
import CircularProgress from "@material-ui/core/CircularProgress";

function PhoneMask(props) {
    const { mask, inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}

class AddressFields extends Component {

    constructor(props) {
        super(props)
        this.state = {
            shopName: '',
            ownerName: '',
            ownerEmail: '',
            phoneNumber: '',
            address: '',
            state: '',
            city: '',
            zipCode: '',
            tax: '',
            isDialogOpen: false,
            isLoadingSave: false,
            loading: false
        }

        this.stateCopy = Object.assign(this.state, {})

        this.props.getShopInfoAction(this.props.shopId,
            (resp) => {
                if(resp.val != false) {
                    this.stateCopy = Object.assign(this.stateCopy, resp, {})
                    this.setState(resp)
                }
            },
            (error) => {
                console.log("getshop info error", error)
            })

        this.onSaveClicked = this.onSaveClicked.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.onDialogYesClicked = this.onDialogYesClicked.bind(this)
        this.isStateDifferent = this.isStateDifferent.bind(this)
    }
     componentDidMount() {
        this.setState({loading: true});
        this.props.getShopInfoAction(parseInt(this.props.shopId),
            (resp) => this.setState({loading: false, shopName: resp.shopName}));
     }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    onSaveClicked() {
        this.setState({
            isDialogOpen: true
        })
        MixpanelTrack('Save Shop Info')
    }

    handleDialogClose() {
        this.setState({isDialogOpen: false})
    }

    isValidForm(addressFields) {
        console.log('fields', addressFields)
        for(var key in addressFields) {
            var value = addressFields[key]
            if(typeof value !== "boolean" && (value === "" || value === undefined)
                && key !== 'bannerUrl' && key !== 'shopIdGuid' && key !== 'chainShopId' && key !== 'logoUrl' && typeof value != "number") {
                return false
            }
        }
        return true
    }

    onDialogYesClicked(handleNextStep) {
        this.handleDialogClose()
        this.setState({isLoadingSave: true})
        MixpanelTrack('Save Shop Info Confirmed')

        const paramsObj = Object.assign({}, this.state, {
            shopId: this.props.shopId,
            phoneNumber: this.state.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g,''),
            zipCode: this.state.zipCode.trim(),
            tax: (typeof this.state.tax === 'string')? parseFloat(this.state.tax.replace('%', '')) : this.state.tax
        })

        //remove the fields that aren't included in this view
        delete paramsObj['facebookUrl']
        delete paramsObj['instagramUrl']
        delete paramsObj['pickup_url']
        delete paramsObj['banner_url']
        delete paramsObj['logo_url']
        if(this.props.activeStep == undefined) {    //get rid of tax if this is within the dashboard
            delete paramsObj['tax']
        }
        delete paramsObj['logoUrl']
        delete paramsObj['pickupUrl']
        if(!this.isValidForm(paramsObj)) {
            this.setState({isLoadingSave: false})
            NotificationManager.error("All Fields must be filled", "Error Saving Info!", 5000)
            return
        }
        this.props.updateShopInfoAction(paramsObj,
            (resp) => {
                if(resp == "True") {
                    if(typeof handleNextStep === 'function') {
                        handleNextStep()
                    } else {
                        NotificationManager.success("The new Shop Info is now saved in CoffeePass!", "Success")
                        this.stateCopy = Object.assign(this.state, {})
                        this.setState(this.state)
                    }
                    this.setState({isLoadingSave: false})
                } else {
                    NotificationManager.error("There was an Error saving the Shop's Info. Are you sure this is the correct address? If the problem persists contact support@coffeepass.io.", "Error Saving Info!", 9000)
                    this.setState({isLoadingSave: false})
                }
            },
            (error) => {
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Info!", 9000)
                this.setState({isLoadingSave: false})
            })
    }

    isStateDifferent() {
        for(var key in this.state) {
            var val = this.state[key]
            const copyVal = this.stateCopy[key]
            if(key == 'phoneNumber' && typeof val === 'string') {
                val = this.state.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g,'')
            }
            if(val != copyVal) {
                return true
            }
        }
        return false
    }

    render() {
        return (
            this.state.loading ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CircularProgress size={36} />
                    </div>
                    :
            <form className="row" autoComplete="off">

                <div className="col-md-12 col-12">
                    <TextField
                        required
                        id="name"
                        label="Shop Name"
                        value={this.state.shopName}
                        onChange={this.handleChange('shopName')}
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 col-12">
                    <FormControl>
                        <InputLabel>Shop Phone Number *</InputLabel>
                        <Input
                        required
                            value={this.state.phoneNumber}
                            onChange={this.handleChange('phoneNumber')}
                            inputComponent={PhoneMask}
                        />
                    </FormControl>
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        required
                        id="ownerName"
                        label="Owner/Manager Name"
                        value={this.state.ownerName}
                        onChange={this.handleChange('ownerName')}
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        required
                        id="ownerEmail"
                        label="Owner/Manager Email"
                        value={this.state.ownerEmail}
                        onChange={this.handleChange('ownerEmail')}
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        required
                        id="address"
                        label="Address"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        defaultValue=""
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        required
                        id="city"
                        label="City"
                        value={this.state.city}
                        onChange={this.handleChange('city')}
                        defaultValue=""
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-3 col-12">
                    <FormControl style={{minWidth: 150}} margin="normal">
                        <InputLabel>State *</InputLabel>
                        <Select
                            value={this.state.state}
                            onChange={this.handleChange('state')}
                            required
                            inputProps={{
                                name: 'state'
                            }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {states.map(obj => {
                                return (<MenuItem value={obj.abbreviation}>{obj.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3 col-12">
                    <TextField
                        required
                        id="zipCode"
                        label="Zip"
                        value={this.state.zipCode}
                        onChange={this.handleChange('zipCode')}
                        defaultValue=""
                        margin="normal"
                        fullWidth
                        type='number'
                    />
                </div>
                {this.props.activeStep != undefined?          //if this is being used for onboarding
                    <div className="col-12">
                        <TextField
                            required
                            label="Tax"
                            value={this.state.tax}
                            onChange={this.handleChange('tax')}
                            margin="normal"
                            InputProps={{
                                inputComponent: TaxMask,
                            }}
                        />
                        <PopoverItem
                            placement={'right'}
                            id={'myinfoId1'}
                            title="What is This?"
                            text="Some states require tax for every drink/food transaction. Put the percentage of the transaction that is taxed here. If your state doesn't require tax, leave this as 0%." />
                    </div>
                    :
                    null
                }
                <div className="col-md-12 col-12" style={styles.buttonContainer}>
                    <SaveStepButton {...this.props}
                        isStateDifferent={this.isStateDifferent}
                        onSaveClicked={this.onSaveClicked}
                        isLoadingSave={this.state.isLoadingSave}
                        onDialogYesClicked={(handleNext)=> this.onDialogYesClicked(handleNext)} />
                </div>
                <SaveSureDialog
                    isDialogOpen= {this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={this.handleDialogClose}
                    dialogDataToSave="shop info"
                    />
                <NotificationContainer />
            </form>
        )
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        shop: selectors(state).shop
    }
}
const actionCreators = {
    updateShopInfoAction,
    getShopInfoAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(AddressFields);
