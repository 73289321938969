import React, { useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../app/Header";
import { AdminSideNav } from "./AdminSideNav";
import Footer from "../app/Footer";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../constants/ActionTypes";
import Overview from "../component/dashboard/analytics/overview/Overview";
import MenuBuilder from "../component/dashboard/menu/MenuBuilder/MenuBuilder";
import { isIOS, isMobile } from "react-device-detect";
import AdminOverview from "./AdminOverview";
import DefaultModifiers from "../component/dashboard/menu/defaultmodifiers/DefaultModifiers";
import TopNav from "../app/TopNav";

const App = ({ match, drawerType, navigationStyle, horizontalNavPosition }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "fixed-drawer"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "collapsible-drawer"
    : "mini-drawer";

  useLayoutEffect(() => {
    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return () => {
      document.body.classList.remove("ios-mobile-view-height");
    };
  }, [isIOS, isMobile]);

  const onToggleCollapsedNav = () =>
    setMobileNavOpen((prevState) => !prevState);

  return (
    <div className={`app-container ${drawerStyle}`}>
      <AdminSideNav
        onToggleCollapsedNav={onToggleCollapsedNav}
        mobileNavOpen={mobileNavOpen}
      />

      <div className="app-main-container">
        <div
          className={`app-header ${
            navigationStyle === HORIZONTAL_NAVIGATION
              ? "app-header-horizontal"
              : ""
          }`}
        >
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === ABOVE_THE_HEADER && (
              <TopNav styleName="app-top-header" />
            )}
          <Header isAdmin toggleCollapsedNav={onToggleCollapsedNav} />
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
        </div>
        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            <Switch>
              <Route path={`${match.url}/menu`} component={MenuBuilder} />
              <Route path={`${match.url}/default-modifiers`} component={DefaultModifiers} />
              <Route path={`${match.url}/overview`} component={AdminOverview} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

App.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  drawerType: PropTypes.string.isRequired,
  navigationStyle: PropTypes.string.isRequired,
  horizontalNavPosition: PropTypes.string.isRequired,
};

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
  };
};

export const AdminApp = withRouter(connect(mapStateToProps)(App));
