import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "react-notifications/lib/notifications.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../styles/jumbo.css";
import blueTheme from "./themes/blueTheme";
import darkTheme from "./themes/darkTheme";
import AppLocale from "../lngProvider";
import { BLUE } from "../constants/ThemeColors";

import MainApp from "../app/index";
import { AdminApp } from "../admin";
import ShopLanding from "../component/shopLanding/ShopLanding";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { setInitUrl } from "../actions";
import RTL from "../util/RTL";
import asyncComponent from "../util/asyncComponent";
import Home from "../component/home/Home";
import ShopOnboarding from "./ShopOnboarding/ShopOnboarding";
import AboutUs from "../component/aboutUs/AboutUs";
import ContactUs from "../component/contactUs/ContactUs";
import ReactPixel from "react-facebook-pixel";
import TermsOfService from "../component/termsOfService/TermsOfService";
import PrivacyPolicy from "../component/termsOfService/Privacy";
import { NotificationContainer } from "react-notifications";
import ResetPassword from "./ResetPassword";
import Verify from "../component/verify";
import VerifyShopUser from "../component/verifyShopUser";

const RestrictedRoute = ({ component: Component, authUser, shop, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      shop.shopId ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentWillMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    ReactPixel.init("324175168372587");
  }

  shouldComponentUpdate(prevProps) {
    // have to manually trigger a re-render now for path changes
    // trigger a re-render when the shop-id changes that will re-hydrate the component tree
    return (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.shop.shopId !== this.props.shop.shopId
    );
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
    }
    return applyTheme;
  }

  render() {
    const {
      match,
      location,
      themeColor,
      isDarkTheme,
      locale,
      authUser,
      initURL,
      isDirectionRTL,
      shop,
    } = this.props;
    let applyTheme = createMuiTheme(BLUE);
    if (isDarkTheme) {
      applyTheme = createMuiTheme(darkTheme);
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }

    if (
      location.pathname === "/dashboard" ||
      location.pathname === "app/dashboard"
    ) {
      if (shop.shopId === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/signin"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    const isCoffeePassAdmin = localStorage.getItem("isCoffeePassAdmin");
    if(location.pathname === "/app/overview" && isCoffeePassAdmin === "true") {
      return <Redirect to={"/admin/overview"} />;
    }

    if (location.pathname === "/signin" || location.pathname === "/signup") {
      document.body.classList.add("background-white");
    } else {
      document.body.classList.remove("background-white");
    }

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={authUser}
                    shop={shop}
                    component={MainApp}
                  />

                  <RestrictedRoute
                      path={`${match.url}admin`}
                      authUser={authUser}
                      shop={shop}
                      component={AdminApp}
                  />

                  <Route path="/signin" component={SignIn} />
                  <Route path="/signup" component={SignUp} />
                  <Route path="/passwordreset" component={ResetPassword} />
                  <Route path="/onboarding" component={ShopOnboarding} />
                  <Route path={`/shopLanding`} component={ShopLanding} />
                  {/*<Route path={`/aboutUs`} component={AboutUs} />*/}
                  <Route path={`/contactUs`} component={ContactUs} />
                  <Route path={"/verify"} component={Verify} />
                  <Route path={"/staff-verify"} component={VerifyShopUser} />
                  <Route path={"/terms"} component={TermsOfService} />
                  <Route path={"/privacy"} component={PrivacyPolicy} />
                  <Route path="/" component={Home} />
                  <Route
                    component={asyncComponent(() => import("./Error404"))}
                  />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { themeColor, sideNavColor, darkTheme, locale, isDirectionRTL } =
    state.settings;
  const { authUser, initURL, shop } = state.auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    shop,
  };
};

export default connect(mapStateToProps, { setInitUrl })(App);
