import React from 'react';
import CustomScrollbars from "../../../../util/CustomScrollbars";
import Option from './Option';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addMenuOptionAction } from '../../../../actions/Menu';
import { connect } from 'react-redux';
import { types } from '../../../../constants/ActionTypes';
import IconButton from '@material-ui/core/IconButton';
import { updateCurrentItemAction } from '../../../../actions/MenuBuilder';

class OptionList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showValuesOptionId: -1
        }

        this.onShowValues = this.onShowValues.bind(this)
    }

    onShowValues(id) {
        this.setState({ showValuesOptionId: (this.state.showValuesOptionId === id)? -1 : id })
    }

    clickAddValue() {
        this.props.addMenuOptionAction()
        //show the new options values
        setTimeout(function() {
            this.setState({showValuesOptionId: this.props.options[this.props.options.length - 1].menuOptionId})
        }.bind(this), 300)
    }

    render() {
        var options = this.props.options.map((option) => {
            return Object.assign(option, {}, {
                showValues: this.state.showValuesOptionId === option.menuOptionId
            })
        })

        return (
            <div>
                <div className="row d-flex" style={styles.borderBottom}>
                    <IconButton
                        onClick={() => this.props.updateCurrentItemAction(null)}>
                        <i className="zmdi zmdi-arrow-back"/>
                    </IconButton>
                    <span style={styles.itemName}>{(this.itemName == "")? "No Item Name" : this.props.itemName}</span>
                </div>
                <Droppable droppableId="options-droppable-id">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} className="module-list">
                            <CustomScrollbars className="module-list-scroll scrollbar"
                                            style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                                {options.map((option, index) =>
                                    <Draggable key={index} draggableId={index} index={index}>
                                        {(provided, snapshot) => {
                                            if (snapshot.isDragging) {
                                                provided.draggableProps.style = {
                                                    ...provided.draggableProps.style,
                                                    left: provided.draggableProps.style.offsetLeft,
                                                    top: provided.draggableProps.style.offsetTop
                                                }
                                            }
                                            return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Option key={index}
                                                        i={index}
                                                        menuItemId={this.props.menuItemId}
                                                        showValues={option.showValues}
                                                        onShowValues={(optionId) => this.onShowValues(optionId)}
                                                        option={option}
                                                        builderType={types.MENU_BUILDER}/>
                                            </div>
                                        )}}
                                    </Draggable>
                                )}
                                <Button color="primary"
                                        onClick={() => this.clickAddValue()}
                                        style={styles.button}>Add Modifier</Button>
                            </CustomScrollbars>
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

const styles = {
    button: {
        marginTop: 10,
        marginBottom: 10
    },
    itemName: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        marginLeft: 10
    },
    borderBottom: {
        borderBottom: 'solid 1px #e9ecef'
    }
}

OptionList.propTypes = {
    options: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired
}

const mapStateToProps = state => {
    return {
        nothing: ""
    }
}
const actionCreators = {
    addMenuOptionAction,
    updateCurrentItemAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(OptionList)
