import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {NotificationContainer, NotificationManager} from "react-notifications";
const  ComplimentaryDialog = ({isDialogOpen, handleClose, complimentaryShops, onSuccess, onSave, shopId, itemId, itemType, menuItemId, menuOptionId}) => {
    const handleSave = async () => {
        const body = {
            shopId,
            itemId,
            complimentaryMemberships: Array.from(listOfShops),
            menuItemId,
            menuOptionId
        }
        onSave(body, () => {
            onSuccess();
            handleClose()
        }, () => {
            NotificationManager.error("Error Saving Info!","There was an issue saving... please try again", 5000)
        });
    }
    let listOfShops = new Set();
    complimentaryShops.forEach(s => {
        if(s[itemType].includes(itemId)) {
            listOfShops.add(s.membershipId)
        }
    })
    const isCoffeePassAdmin = localStorage.getItem("isCoffeePassAdmin") === 'true';
    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Set Complimentary"}</DialogTitle>
            <DialogContent>
                <span>To make change to CoffeePass memberships, please speak to a member of the CoffeePass team.</span>
                <DialogContentText id="alert-dialog-description">
                    {complimentaryShops.map((s, index) =>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={s.isCoffeePassMembership && !isCoffeePassAdmin}
                                    defaultChecked={s[itemType].includes(itemId)}
                                    onChange={(event) => event.target.checked ? listOfShops.add(s.membershipId) : listOfShops.delete(s.membershipId)}
                                    color="primary" />
                            }
                            label={s.name} />)
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleSave()}>
                    Save
                </Button>
            </DialogActions>
            <NotificationContainer />
        </Dialog>
    )
}

export default ComplimentaryDialog;
