

import {
    GET_ALL_SHOPS,
    GET_SHOP, GET_SHOP_USERS
} from '../constants/ActionTypes';

const INIT_STATE = {
    payload: {
        shopName: '',
        phoneNumber: '',
        ownerName: '',
        ownerEmail: '',
        address: '',
        state: '',
        city: '',
        zipCode: '',
        isDialogOpen: false,
        allShops: []
    },
    shopUsers: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SHOP: {
            return {
                ...state,
                payload: action.payload
            }
        }
        case GET_SHOP_USERS: {
            return {
                ...state,
                shopUsers: action.payload
            }
        }
        case GET_ALL_SHOPS: {
            return {
                ...state,
                allShops: action.payload
            }
        }
        default:
            return state
    }
}
