import { baseUrl } from '../constants/baseUrl';
import {
    NOT_IN_STORE,
    GET_SHOP_MEMBERSHIP, GET_SHOP_MEMBERSHIPS
} from '../constants/ActionTypes';

export const updateMembershipAction = (shopMembershipJson, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: shopMembershipJson,
    meta: {
        type: 'api',
        url: baseUrl + '/shop_membership',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(shopMembershipJson)
        }
    }
})

export const getMembershipsAction = (shopId, onSuccess, onFailure) => ({
    type: GET_SHOP_MEMBERSHIPS,
    payload: {},
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_memberships_for_shop',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shopId: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const getMembershipAction = (membershipId, shopId, onSuccess, onFailure) => ({
    type: GET_SHOP_MEMBERSHIP,
    payload: {},
    meta: {
        type: 'apijson',
        url: baseUrl + '/shop_membership/',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            membershipId: membershipId,
            shopId: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const updateUserMembershipStatusAction = (paramsObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramsObj,
    meta: {
        type: 'api',
        url: baseUrl + '/change_membership_status',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(paramsObj)
        }
    }
})

export const deactivateUserMembershipAction = (paramsObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramsObj,
    meta: {
        type: 'api',
        url: baseUrl + '/deactivate_user_membership',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(paramsObj)
        }
    }
})
