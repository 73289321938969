import React, { Component } from 'react';
import HeaderBlock from '../home/HeaderBlock';
import Footer from '../home/Footer';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {verifyShopUserEmailAction} from "../../actions/VerifyEmail";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../util/IntlMessages";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class VerifyShopUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            error: false,
            errorMessage: "",
            submitError: false
        }
        this.setPassword = this.setPassword.bind(this);
    }
    componentDidMount() {
        MixpanelTrack("User Verified");
    }

    async setPassword() {
        if(this.state.password.length < 8 || this.state.confirmPassword.length < 8) {
            this.setState({error: true, errorMessage: "Passwords must be 8 characters."})
        } else if (this.state.password !== this.state.confirmPassword) {
            this.setState({error: true, errorMessage: "Passwords do not match."})
        } else {
            const params = new URLSearchParams(this.props.location.search.replace("+", '%2B'));
            this.props.verifyShopUserEmailAction({email:params.get('email'), token: params.get('token'), password: this.state.password},
                (response) => {
                    if(!response.error) {
                        this.setState({loading: false, success: true})
                    } else {
                        this.setState({loading: false, submitError: true})
                    }
                },
                () => this.setState({loading: false, success: false, submitError: true}))
        }
    }

    getMessage() {
        if(this.state.success) {
            return <><h2>Your password has been set!</h2><span style={{marginBottom: 50}}><Link to={"/signin"}>Click Here</Link> to login</span></>
        } else if(this.state.submitError) {
            return <h2 style={{marginBottom: 50}}>There was an issue setting your password. Please contact support.</h2>
        }
    }

    render() {
        return (
            <div className='app'>
                <div className='aboutUsWrapper'>
                    <div className="topBlock">
                        <div className="navBarBackground">
                            <HeaderBlock isMainPage={false} />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: "column", justifyItems: "center", alignItems: "center"}}>
                        {this.state.loading ?
                            <div style={styles.progress} >
                                <CircularProgress size={50} />
                            </div>
                            :
                            !this.state.success && <div style={styles.message}>
                                <h3>Set Your Password for the CoffeePass Web Dashboard</h3>
                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({password: event.target.value, error: false})}
                                    label={<IntlMessages id="appModule.password"/>}
                                    fullWidth
                                    error={this.state.error}
                                    defaultValue={this.state.password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({confirmPassword: event.target.value, error: false})}
                                    label={"Confirm Password"}
                                    fullWidth
                                    error={this.state.error}
                                    defaultValue={this.state.password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                {this.state.error && <h3 style={{color: "red", textAlign:'center'}}>{this.state.errorMessage}</h3>}
                                <Button color="primary" onClick={() => this.setPassword()}>
                                    Save
                                </Button>
                                {this.getMessage()}
                            </div>}
                        {(this.state.success || this.state.submitError) && this.getMessage()}

                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

const styles = {
    progress: {
        display: 'flex',
        margin: 200,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
    message: {
        display: 'flex',
        margin: 50,
        paddingBottom: 0,
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400,
        textAlign: 'center'
    }
}


const mapStateToProps = state => {
    return {
    }
}
const actionCreators = {
    verifyShopUserEmailAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(withRouter(VerifyShopUser));
