import {
  SHOP_LOGIN,
    SIGNOUT_USER,
  SHOP_SIGNUP,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SET_SELECTED_SHOP,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  shop: { shopId: localStorage.getItem("shop_id") },
  access_token: null,
  userId: parseInt(localStorage.getItem("userId")),
  allPermissions: JSON.parse(localStorage.getItem("allPermissions")) ?? [],
  selectedPermissions: JSON.parse(localStorage.getItem("permissions")),
  authuser: JSON.parse(localStorage.getItem("authUser"))
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOP_LOGIN:
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("userId", action.payload.shopLoginId);
      localStorage.setItem(
        "allPermissions",
        JSON.stringify(action.payload.permissions)
      );
      localStorage.setItem(
          "authUser",
          JSON.stringify(action.payload)
      );
      if (action.payload.permissions.length === 1) {
        localStorage.setItem(
          "permissions",
          JSON.stringify(action.payload.permissions[0].permissions)
        );
        if(action.payload.permissions[0].shop_id === -1 && action.payload.isFromLogin) {
          localStorage.setItem("isCoffeePassAdmin", "true");
        }
        localStorage.setItem(
          "shop_id",
          JSON.stringify(action.payload.permissions[0].shop_id)
        );
      }
      return {
        ...state,
        shop: {
          shopId:
            action.payload.permissions.length === 1
              ? action.payload.permissions[0].shop_id
              : "multiple",
        },
        loader: false,
        access_token: action.payload.access_token,
        authuser: action.payload,
        loggedIn: action.payload.val == "True",
        allPermissions: action.payload.permissions,
        selectedPermissions: action.payload.permissions[0].permissions,
        userId: action.payload.shopLoginId,
      };
    case SET_SELECTED_SHOP:
      localStorage.setItem("shop_id", action.payload.shopId);
      localStorage.setItem(
        "permissions",
        JSON.stringify(action.payload.permissions === undefined ? state.selectedPermissions : action.payload.permissions.permissions)
      );
      if(action.payload.isFromLogin === true){
        localStorage.setItem("isCoffeePassAdmin", action.payload.shopId === -1 ? "true" : "false");
      }
      return {
        ...state,
        shop: { shopId: action.payload.shopId },
        selectedPermissions: action.payload.permissions === undefined ? state.selectedPermissions : action.payload.permissions.permissions,
      };
    case SHOP_SIGNUP:
      localStorage.setItem("shop_id", action.payload.shopId);
      return {
        ...state,
        shop: action.payload,
        loader: false,
        authuser: action.payload,
        loggedIn: action.payload.val == "True",
      };
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        shop: {shopId: null}
      };
    }
    case INIT_URL: {
      return {
        ...state,
        access_token: localStorage.getItem("access_token"),
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        shop: { shopId: null },
        initURL: "/app/dashboard/",
        loader: false,
        selectedPermissions: null,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
