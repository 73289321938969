import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "../../actions";
import IntlMessages from "../../util/IntlMessages";
import { getShopInfoAction } from "../../actions/ShopInfo";
import { MixpanelTrack } from "../../util/MixpanelHelper";
import selectors from "../../reducers/selectors";
import {withRouter} from "react-router-dom";

class UserInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
      url:
        "https://s3.amazonaws.com/coffeepass-shop-info/shop_logo/" +
        this.props.shopId +
        "_logo.png",
    };
  }

  getInfo() {
    this.props.getShopInfoAction(
      this.props.location.pathname.includes('admin') ? -1 : this.props.shopId,
      (resp) => {
        if (resp.val != false) {
          if (
            resp.logoUrl != "" ||
            resp.logoUrl != null ||
            resp.logoUrl != "undefined"
          ) {
            this.setState({ url: resp.logoUrl });
          }
        }
      },
      (error) => {
        console.log("getshop info error", error);
      }
    );
  }

  componentDidMount() {
    this.getInfo();
  }

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.shopId !== this.props.shopId && !this.props.location.pathname.includes('admin')) {
      this.getInfo();
    }
  }

  render() {
    var name = this.props.user.firstName;
    let shopName = this.props.shop.shopName;
    if (shopName != undefined && name.length > 15) {
      shopName = shopName.substring(0, 15) + "...";
    }

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt="..."
          src={this.state.url}
          className="user-avatar "
          colordefault="white"
          style={styles.logo}
        />
        <div className="user-detail" style={{display: 'flex', flexDirection: 'column', justifyItems: 'center'}}>
          <h4 className="user-name" onClick={this.handleClick}>
            {name}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
          <h4 style={{margin: 0}}>
            {shopName}
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              width: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
              MixpanelTrack("Logged Out");
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const styles = {
  logo: {
    backgroundColor: "white",
  },
};

const mapStateToProps = (state) => {
  return {
    locale: state.settings,
    shop: selectors(state).shop,
    shopId: selectors(state).shopId,
    user: state.auth.authuser
  };
};
const actionCreators = {
  userSignOut,
  getShopInfoAction,
};

export default connect(mapStateToProps, actionCreators)(withRouter(UserInfo));
