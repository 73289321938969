
import UniqueIdGenerator from '../util/UniqueIdGenerator';
import {
    GET_MEMBER_UPDATES,
    GET_SHOP_MEMBERSHIP, GET_SHOP_MEMBERSHIPS
} from '../constants/ActionTypes';


const getUniqueId = () => {
    const uniqueIdGenerator = new UniqueIdGenerator()
    return uniqueIdGenerator.getId()
}

const INIT_STATE = {
    payload: {
        membershipId: null,
        isMembershipEnabled: false,
        callToAction: '',
        description: '',
        redemptionFrequency: 4,
        termsUrl: '',
        policyUrl: '',
        memberBenefits: [],
        questions: [],
        manualApproval: false,
        globalDiscountEnabled: true,
        globalDiscount: 0.0,
        startedDate: '',
        lastUpdatedDate: '',
        membershipUpdates: [],
        freeStripePriceId: null,
        isGlobalMembership: false
    },
    clipboard: {
        memberBenefit: {},
        question: {}
    },
    toDelete: {
        memberBenefits: [],
        questions: []
    },
    allMemberships: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SHOP_MEMBERSHIP:{
            return {
                ...state,
                payload: action.payload,
                toDelete: action.toDelete
            }
        }
        case GET_SHOP_MEMBERSHIPS: {
            return {
                ...state,
                allMemberships: action.payload

            }
        }
        default:
            return state
    }
}
