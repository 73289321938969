import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {NotificationContainer, NotificationManager} from "react-notifications";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const UserAddDialog = ({isDialogOpen, handleClose, shops, onSave}) => {
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const handleSave = async () => {
        let body = {
            email: userEmail,
            firstName: userFirstName,
            lastName: userLastName,
        }
        let modifiedPermissions = [];
        shops.forEach(s => {
            let shop_id = s.shopId;
            let shop = {
                shop_id,
                permissions: {
                    analytics: formRef.current[`analytics-${shop_id}`].checked,
                    membership: formRef.current[`membership-${shop_id}`].checked,
                    menuBuilding: formRef.current[`menuBuilding-${shop_id}`].checked,
                    shopManagement: formRef.current[`shopManagement-${shop_id}`].checked,
                    userManagement: formRef.current[`userManagement-${shop_id}`].checked,
                }
            }
            modifiedPermissions.push(shop);
        })
        body.shopWithPerms = modifiedPermissions

        onSave(body);
    }
    const formRef = React.createRef();
    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Add a Shop User"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <form ref={formRef}>
                        <div style={{marginBottom: 25}}>
                            <FormControl>
                                <InputLabel>User First Name</InputLabel>
                                <Input
                                    required
                                    id={"firstName"}
                                    onChange={(e) => setUserFirstName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel>User Last Name</InputLabel>
                                <Input
                                    required
                                    id={"lastName"}
                                    onChange={(e) => setUserLastName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel>User Email</InputLabel>
                                <Input
                                    required
                                    id={"email"}
                                    type={"email"}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                />
                            </FormControl>
                        </div>
                        {shops.map((s) => {
                            const checkBoxes = [
                                {
                                    display: "Analytics",
                                    value: "analytics"
                                },
                                {
                                    display: "Shop Management",
                                    value: "shopManagement"
                                },
                                {
                                    display: "Membership",
                                    value: "membership"
                                },
                                {
                                    display: "Menu Building",
                                    value: "menuBuilding"
                                },
                                {
                                    display: "User Management",
                                    value: "userManagement"
                                },


                            ]
                            return (
                                <div style={{display: "flex", flexDirection: "column", marginBottom: 10, minWidth: 400}}>
                                    <h3 style={{textDecoration: "underline"}}>{s.shopName}</h3>
                                    {checkBoxes.map(c =>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={false}
                                                    color="primary"
                                                    name={`${c.value}-${s.shopId}`}
                                                    id={`${c.value}-${s.shopId}`}
                                                />
                                            }
                                            label={c.display}/>
                                    )}

                                </div>)
                        })
                        }
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={(userEmail === "" || userFirstName === "" || userLastName === "")} color="primary" onClick={() => handleSave()}>
                    Save
                </Button>
            </DialogActions>
            <NotificationContainer/>
        </Dialog>
    )
}

export default UserAddDialog;
