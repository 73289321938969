import {NOT_IN_STORE, GET_SHOP, GET_SHOP_USER, GET_SHOP_USERS, GET_ALL_SHOPS} from "../constants/ActionTypes";
import { baseUrl } from '../constants/baseUrl';


export const updateShopInfoAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/update_shop_info',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            shop_name: paramObj.shopName,
            owner_name: paramObj.ownerName,
            owner_email: paramObj.ownerEmail,
            phone_number: paramObj.phoneNumber,
            address: paramObj.address,
            city: paramObj.city,
            state: paramObj.state,
            zip_code: paramObj.zipCode,
            tax: paramObj.tax
        },
        options: {
            method: 'PUT'
        }
    }
})

export const updateShopTaxPausedAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/update_shop_tax_paused',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            tax: paramObj.tax,
            is_paused: paramObj.isPaused,
            facebook_url: paramObj.facebookUrl,
            instagram_url: paramObj.instagramUrl
        },
        options: {
            method: 'PUT'
        }
    }
})

export const addShopToChain = (body, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: body,
    meta: {
        type: 'apijson',
        url: baseUrl + '/add_shop_to_chain',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
})

export const updateShopOnboardedAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/update_shop_live_onboarded',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            is_onboarded: paramObj.isOnboarded,
            // is_live: paramObj.isLive
        },
        options: {
            method: 'PUT'
        }
    }
})

export const updateShopHoursAction = (hoursJson, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: hoursJson,
    meta: {
        type: 'api',
        url: baseUrl + '/update_shop_hours',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(hoursJson)
        }
    }
})

export const getShopInfoAction = (shopId, onSuccess, onFailure) => ({
    type: GET_SHOP,
    payload: shopId,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_shop_info',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const getAllShops = (onSuccess, onFailure) => ({
    type: GET_ALL_SHOPS,
    payload: null,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_all_shops',
        onSuccess: onSuccess,
        onFailure: onFailure,
        options: {
            method: 'GET'
        }
    }
})

export const getShopRewardsAction = (shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: shopId,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_shop_rewards',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const getShopUsersAction = (shopIds, onSuccess, onFailure) => ({
    type: GET_SHOP_USERS,
    payload: null,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_shop_users',
        onSuccess: onSuccess,
        onFailure: onFailure,
        options: {
            method: 'POST',
            body: JSON.stringify(shopIds)
        }
    }
})

export const setShopUserPermissionsAction = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: params,
    meta: {
        type: 'apijson',
        url: baseUrl + '/set_shop_user_permissions',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_login_id: params.shop_login_id
        },
        options: {
            method: 'POST',
            body: JSON.stringify(params.body)
        }
    }
})

export const addShopUser = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: params,
    meta: {
        type: 'apijson',
        url: baseUrl + '/add_new_or_existing_staff_member_to_shop',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {

        },
        options: {
            method: 'POST',
            body: JSON.stringify(params)
        }
    }
})

export const switchShopRewardsAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/switch_shop_rewards',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shopId: paramObj.shopId,
            isEnabled: paramObj.isEnabled
        },
        options: {
            method: 'PUT'
        }
    }
})

export const getShopUserAction = (shopId, onSuccess, onFailure) => ({
    type: GET_SHOP_USER,
    payload: shopId,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_shop_user',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const getShopHoursAction = (shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: shopId,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_shop_hours',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const doesUrlExistAction = (url, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: null,
    meta: {
        type: 'api',
        url: url,
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'GET'
        }
    }
})

export const setUploadShopImageAction = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: null,
    meta: {
        type: 'api',
        url: baseUrl + '/upload_image',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: params.shopId,
            is_pickup_image: params.isPickupImage? "True" : "False"
        },
        options: {
            method: 'PUT'
        }
    }
})

export const saveStripeTokenAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: {},
    meta: {
        type: 'apijson',
        url: baseUrl + '/save_stripe_shop_token',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            authorization_code: paramObj.authorizationCode
        },
        options: {
            method: 'PUT'
        }
    }
})

export const getShopStripeIdAction = (shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: {},
    meta: {
        type: 'api',
        url: baseUrl + '/get_shop_stripe_id',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})
