import React from 'react';
import './overviewStyle.css'
import {
    data,
    data1,
    data2,
    customerFreqData,
    products,
    statData,
    emptyData,
    recencyData
} from './graphData'
import ContainerHeader from '../../util/ContainerHeader';
import CardMenu from './components/CardMenu';
import PopularProductTable from './components/PopularProducts/PopularProductTable';
import YearSalesReport from './components/YearSalesReport';
import NewCustomers from './components/NewCustomers';
import CustomerFreq from './components/CustomerFrequency/CustomerFreq';
import RecentOrders from './components/RecentOrders/RecentOrders';
import CustomerRecency from './components/CustomerRecency/CustomerRecency';
import OrdersToday from './components/OrdersToday';
import GrossSalesToday from './components/GrossSalesToday';
import AverageSpent from './components/AverageSpent';
import Customers from './components/Customers';
import { MixpanelTrack } from '../../../../util/MixpanelHelper';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import selectors from '../../../../reducers/selectors';
import { getShopInfoAction } from '../../../../actions/ShopInfo';

class Overview extends React.Component {


    onOptionMenuSelect = event => {
        this.setState({ menuState: true, anchorEl: event.currentTarget });
    };
    handleRequestClose = () => {
        this.setState({ menuState: false });
    };

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            menuState: false,
        }
    }

    componentDidMount() {
        MixpanelTrack("Visited Analytics Overview Page")
        console.log(this.props.permissions)
        //// Disabled UserEngineWidget temporarily as we don't have access to the widget's admin panel
        // this.props.getShopInfoAction(this.props.shopId,
        //     (resp) => {
        //         if(resp.val != false) {
        //             const script = document.createElement("script")

        //             script.src = "https://userengine-widget.now.sh/widget.js"
        //             script.async = false
        //             document.body.appendChild(script)
        //             console.log('owner', resp)
        //             const script2 = document.createElement("script")
        //             script2.innerText = `!function() {
        //                                     setTimeout(() => {
        //                                         UserEngineWidget.init({
        //                                             id: '1rjght5ko6UL-MKKtLDa6rVHfqAkFTn5-mX-ttyWBdKg'
        //                                         });
        //                                         UserEngineWidget.show("${resp.ownerEmail}");
        //                                     }, 1000);
        //                                 }()`
        //             script2.async = true
        //             document.body.appendChild(script2)
        //         }
        //     },
        //     (error) => {
        //         console.log("getshop info error", error)
        //     })

    }

    render() {
        const { anchorEl, menuState } = this.state;
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                { (this.props.shop.isOnboarded != null && !this.props.shop.isOnboarded)?
                    <Redirect to="/onboarding" />
                    :
                    null
                }
                <ContainerHeader match={this.props.match} title="Overview" />

                <div className="row">
                    <OrdersToday data={data} />

                    <GrossSalesToday data={data1} />

                    <Customers data={data2} />

                    <AverageSpent data={data1} />
                </div>

                <div className="row">
                    <YearSalesReport data={statData} />

                    <PopularProductTable data={products} />

                    <CustomerRecency data={recencyData} />

                    <CustomerFreq data={customerFreqData} />
                </div>

                <div className="row">
                    <RecentOrders />

                    <NewCustomers data={emptyData} />
                </div>
                <CardMenu menuState={menuState} anchorEl={anchorEl}
                    handleRequestClose={this.handleRequestClose.bind(this)} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shop: selectors(state).shop,
        shopId: selectors(state).shopId,
        permissions: state.auth.selectedPermissions
    }
}

const actionCreators = {
    getShopInfoAction
}

export default connect(mapStateToProps, actionCreators)(Overview)
