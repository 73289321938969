import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import CardBox from '../analytics/util/CardBox';
import MembersDataTable from './MembersDataTable';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import { getShopUserAction } from '../../../actions/ShopInfo';
import {
    updateUserMembershipStatusAction,
    deactivateUserMembershipAction,
    getMembershipAction
} from '../../../actions/Membership';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class MembersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shopID: this.props.shopId,
            isLoading: false,
            membershipEnabled: false,
            membershipId: 0,
            members: [],
            page: 0,
            buttonsDisabled: false
        };

        this.props.getShopUserAction(this.props.shopId,
            (resp) => {
                this.setState({ membershipEnabled: resp.membershipEnabled, membershipId: resp.membershipId })
                if(resp.membershipEnabled && resp.membershipId > 0){
                    this.getMembers(resp.membershipId);
                }
            })

    }
    componentDidMount() {
        MixpanelTrack("Visited Analytics Page")
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        MixpanelTrack('Change to analytics page ' + page)
    }

    getMembers = (membershipId) => {
        this.setState({ isLoading: true })
        fetch(baseUrl + '/get_all_members?shopId=' + this.props.shopId, {
            method: 'GET',
            headers: {Authorization: `Bearer ${this.props.access_token}`}
        })
        .then(result => result.json())
        .then(result => {
            let members = []
            for(let i = 0; i < result.length; i++) {
               result[i].lastUpdated =  new Date(result[i].lastUpdated).toLocaleDateString("en-US")
                members.push(result[i]);
            }
            this.setState({
                members: members,
                isLoading: false
            })
        })
    }

    formatMembershipData(data) {
        let arrayResult = [
            { value: data.membershipTier, numeric: false, membershipId: data.membershipId, freeStripePriceId: data.freeStripePriceId },
            { value: data.name, numeric: false },
            { value: data.email, numeric: false },
            { value: data.phone, numeric: false },
            { value: data.status, numeric: false },
            { value: ((new Date(data.lastUpdated)).toLocaleDateString("en-US")), numeric: false },
            { value: ("$" + data.price.toFixed(2).toString()), numeric: true },
            { value: data.id, numeric: false },
            { value: data.status, numeric: false }
        ];
        return arrayResult;
    }

    showDownloadButton() {
        return this.state.members.length > 0;
    }

    actionChangeStatus = (actionStatus, memberData, free, cell) => {
        const paramsObj = {
            status: actionStatus,
            membership_id: cell[0].membershipId,
            user_id: memberData.userId,
            free: free
        }
        this.setState({buttonsDisabled: true})
        if(actionStatus.includes('cancelled')){
            this.props.deactivateUserMembershipAction(paramsObj,
                (resp) => {
                    if(resp && JSON.parse(resp).Success){
                        NotificationManager.success(JSON.parse(resp).Success, "Success")
                        this.getMembers(this.state.membershipId)
                        this.setState({buttonsDisabled: false})
                    }
                },
                (error) => {
                    NotificationManager.error("Error: " + error + " If the problem persists contact support@coffeepass.io.", "Error updating membership!", 9000)
                    this.setState({buttonsDisabled: false})
            })
        }else{
            this.props.updateUserMembershipStatusAction(paramsObj,
                (resp) => {
                    if(resp && JSON.parse(resp).Result){
                        NotificationManager.success(JSON.parse(resp).Result, "Success")
                        this.getMembers(this.state.membershipId)
                        this.setState({buttonsDisabled: false})
                    }
                },
                (error) => {
                NotificationManager.error("Error: " + error.error + " If the problem persists contact support@coffeepass.io.", "Error updating membership!", 9000)
                    this.setState({buttonsDisabled: false})
            })
        }
    }

    render() {
        const columnData = [
            { id: 'membershipTier', numeric: false, disablePadding: false, label: 'Membership Tier' },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'phone', numeric: false, disablePadding: false, label: 'Mobile' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
            { id: 'lastUpdated', numeric: false, disablePadding: false, label: 'Last Updated' },
            { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
            { id: 'seedetails', numeric: false, disablePadding: false, label: 'Details' },
            { id: 'action', numeric: true, disablePadding: false, label: 'Action' }
        ];
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Members"} match={this.props.match} />
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    {this.state.members.length > 0 &&
                        <MembersDataTable
                            title="Members List"
                            data={this.state.members}
                            dataFormatter={this.formatMembershipData}
                            columnData={columnData}
                            isLoading={this.state.isLoading}
                            onChangePage={this.handleChangePage}
                            page={this.state.page}
                            onActionChangeStatus= {this.actionChangeStatus}
                            buttonsDisabled={this.state.buttonsDisabled}
                        />
                    }
                </CardBox>
                <NotificationContainer />
                { this.showDownloadButton() && <div className="p-3">
                    <ExcelFile element={<button className="btn btn-primary">Download Membership List</button>} filename="MembershipList">
                        <ExcelSheet data={this.state.members} name="Active Members">
                            <ExcelColumn label="Membership Tier" value="membershipTier"/>
                            <ExcelColumn label="Name" value="name"/>
                            <ExcelColumn label="Email" value="email"/>
                            <ExcelColumn label="Phone" value="phone"/>
                            <ExcelColumn label="Status" value="status"/>
                            <ExcelColumn label="Last Updated" value="lastUpdated"/>
                            <ExcelColumn label="price" value="price"/>
                        </ExcelSheet>
                    </ExcelFile>
                </div> }
            </div>)
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        membership: state.membership,
        access_token: state.auth.access_token

    }
}
const actionCreators = {
    getShopUserAction,
    getMembershipAction,
    updateUserMembershipStatusAction,
    deactivateUserMembershipAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(MembersList);
