import {
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    INIT_URL,
    SHOP_LOGIN,
    SHOP_SIGNUP,
    SHOW_MESSAGE,
    SIGNUP_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER
} from "../constants/ActionTypes";
import { baseUrl } from '../constants/baseUrl';

export const shopLoginAction = (shopObject, onSuccess, onFailure) => ({
    type: SHOP_LOGIN,
    payload: shopObject,
    meta: {
        type: 'apijson',
        url: baseUrl + '/shop_login_v2',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            email: shopObject.email,
            password: shopObject.password
        },
        options: {
            method: 'PUT'
        }
    }
})

export const shopSignupAction = (shopObject, onSuccess, onFailure) => ({
    type: SHOP_SIGNUP,
    payload: shopObject,
    meta: {
        type: 'apijson',
        url: baseUrl + '/create_shop',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            name: shopObject.name,
            email: shopObject.email,
            password: shopObject.password
        },
        options: {
            method: 'PUT'
        }
    }
})

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};

export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};

export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
