import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import CardBox from '../analytics/util/CardBox';
import DataTable from '../analytics/util/DataTable';
import { connect } from 'react-redux';
import DatabaseValidator from './../util/DatabaseValidator';
import LoadingTable from './components/LoadingTable';
import selectors from '../../../reducers/selectors';
import moment from "moment";
import {baseUrl} from "../../../constants/baseUrl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationManager} from "react-notifications";

class AnnouncementHistory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            membershipUpdates: [],
            error: null,
            retracting: false
        }
        let paramObj = {
            shopId: this.props.shopId
        }

        this.onRetractClicked = this.onRetractClicked.bind(this);
        this.formatPayoutData = this.formatPayoutData.bind(this);
        this.getUpdates = this.getUpdates.bind(this);
    }

    async componentDidMount() {
        await this.getUpdates();
    }

    getUpdates() {
        fetch(`${baseUrl}/get_membership_updates?shopId=${this.props.shopId}`, { headers: {Authorization: `Bearer ${this.props.access_token}`}})
            .then(result => result.json())
            .then(response => {
                this.setState({membershipUpdates: response});
            })
            .catch(error => {
                this.setState({error: error});
            });
    }

    onRetractClicked(updateId) {
        this.setState({retracting: true});
        fetch(`${baseUrl}/retract_membership_update?membershipUpdateId=${updateId}`, {method: 'POST', headers: {Authorization: `Bearer ${this.props.access_token}`}})
            .then(response => {
                if(response.ok) {
                    this.setState({retracting: false});
                    this.getUpdates();
                } else {
                    NotificationManager.error("Error Retracting Update", "Please try again or contact a member of the CoffeePass team.", 5000);
                }
            })
    }

    formatPayoutData(data) {
        let statusString = 'Active';
        let now = moment();
        let createdDate = moment(data.created);
        if(data.retracted) {
            statusString = 'Retracted';
        }
        else if(now.diff(createdDate, 'days') > 6) {
            statusString = 'Expired';
        }

        let resultArray = [
            { value: data.updateText, numeric: false },
            { value: `${moment(data.created).toDate().toLocaleDateString("en-US", {timeZone: 'UTC'})}`, numeric: false },
            { value: statusString, numeric: false },
            { value: <Button
                    key={data.membershipUpdateId}
                    variant="raised"
                    color="primary"
                    className="jr-btn"
                    disabled={data.retracted}
                    onClick={() => this.onRetractClicked(data.membershipUpdateId)}>
                    {this.state.retracting?
                        <CircularProgress size={16} style={styles.progress} />
                        :
                        "Retract"
                    }
                </Button>, numeric: false },
            { value: data.updated !== null ? `${moment(data.updated).toDate().toLocaleDateString("en-US", {timeZone: 'UTC'})}` : 'N/A', numeric: false }

        ];
        return resultArray;
    }
    formatDate(date){
        return ((new Date(date)).toLocaleDateString("en-US", {timeZone: 'UTC'}))
    }
    handleChangePage = (event, page) => {
        event.preventDefault();
        this.setState({ page });
    }

    render() {
        const columnData = [
            { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
            { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
            { id: 'action', numeric: false, disablePadding: true, label: 'Action' },
            { id: 'retracted', numeric: false, disablePadding: false, label: 'Retracted Date' },
        ];
        let dv = new DatabaseValidator();
        let dataVis;
        let memberUpdates = this.state.membershipUpdates;
        if (this.state.membershipUpdates.length === 0 ) {
            dataVis = <LoadingTable />
        } else {
            dataVis = <DataTable title="Announcement History" columnData={columnData} dataFormatter={this.formatPayoutData} data={memberUpdates}
                                 onChangePage={this.handleChangePage}
                                 page={this.state.page} />
        }
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match} title="Announcements" />
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    {dataVis}
                </CardBox>
            </div>
        );
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        access_token: state.auth.access_token
    }
}

const actionCreators = {

}

export default connect(
    mapStateToProps,
    actionCreators
)(AnnouncementHistory);
