
import { baseUrl } from '../constants/baseUrl';
import {
    NOT_IN_STORE,
    ITEM_NAME_CHANGE,
    ITEM_PICKUP_TIME_CHANGE,
    ITEM_DISCOUNT_UNIT_CHANGE,
    ITEM_DISCOUNT_VALUE_CHANGE,
    ADD_MENU_ITEM,
    OPTION_VALUE_CHANGE,
    OPTION_TITLE_CHANGE,
    ADD_MENU_OPTION,
    GET_SHOPS_DATABASE,
    ITEM_IMAGE,
    DELETE_MENU_ITEM,
    DELETE_MENU_OPTION,
    DELETE_MENU_VALUE,
    EMPTY_TO_DELETE,
    ADD_MENU_VALUE,
    ON_DRAG_OPTION,
    ON_DRAG_ITEM,
    PASTE_VALUE,
    COPY_VALUE,
    PASTE_OPTION,
    COPY_OPTION,
    PASTE_ITEM,
    VALIDATE_MENU,
    MOVE_ITEM,
    ITEM_AVAILABLE_CHANGE,
    COPY_ITEM,
    CHANGE_COMPLIMENTARY_STATUS,
    CHANGE_UPSELL_STATUS,
    CHANGE_FOOD_VIEW_STATUS,
    CHANGE_DRINK_VIEW_STATUS,
    CHANGE_CATEGORY_VIEW_STATUS,
    CHANGE_CATEGORY_VISIBILITY_STATUS,
    CHANGE_CATEGORY_EXCLUSIVITY_STATUS,
    CHANGE_EVENTS_CATEGORY_STATUS,
    ITEM_QUANTITY_CHANGE,
    EDIT_CATEGORY,
    NEW_CATEGORY,
    ON_DRAG_CATEGORY,
    DELETE_CATEGORY,
    types, GET_MEMBERSHIPS_FOR_ITEM
} from '../constants/ActionTypes';


export const updateShopMenuV3Action = (menuJson, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: menuJson,
    meta: {
        type: 'api',
        url: baseUrl + '/update_shop_menu_v3',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(menuJson)
        }
    }
})

export const removeDeletedMenuAction = (removeJson, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: removeJson,
    meta: {
        type: 'api',
        url: baseUrl + '/remove_deleted_menu_items',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(removeJson)
        }
    }
})

export const getShopMenuAction = (paramObj, onSuccess, onFailure) => ({
    type: GET_SHOPS_DATABASE,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_menu_v3',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            menu_id: paramObj.menuId,
            isFromDashboard: true
        },
        options: {
            method: 'GET'
        }
    }
})

export const getComplimentaryMembershipsForItem = (paramObj, onSuccess, onFailure) => ({
    type: GET_MEMBERSHIPS_FOR_ITEM,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_complimentary_memberships_for_item',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shopId: paramObj.shopId,
            menuItemId: paramObj.menuItemId,
        },
        options: {
            method: 'GET'
        }
    }
})

export const getSubsidies = (optionValueId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: optionValueId,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_subsidies',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            optionValueId
        },
        options: {
            method: 'GET'
        }
    }
})

export const addSubsidies = (subsidies, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: subsidies,
    meta: {
        type: 'apijson',
        url: baseUrl + '/add_subsidies',
        onSuccess: onSuccess,
        onFailure: onFailure,
        options: {
            method: 'POST',
            body: JSON.stringify(subsidies)
        }
    }
})


export const setComplimentaryMembershipsForItem = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/set_complimentary_memberships_for_item',
        onSuccess: onSuccess,
        onFailure: onFailure,
        options: {
            method: 'POST',
            body: JSON.stringify(paramObj)
        }
    }
})

export const setTieredOptions = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/set_options_tiered',
        onSuccess: onSuccess,
        onFailure: onFailure,
        options: {
            method: 'POST',
            body: JSON.stringify(paramObj)
        }
    }
})

export const setValuesComplimentary = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/set_values_complimentary',
        onSuccess: onSuccess,
        onFailure: onFailure,
        options: {
            method: 'POST',
            body: JSON.stringify(paramObj)
        }
    }
})

export const changeItemNameAction = (itemIndex, name) => ({
    type: ITEM_NAME_CHANGE,
    payload: {
        name: name,
        itemIndex: itemIndex
    }
})

export const changeQuantityAction = (itemIndex, quantity) => ({
    type: ITEM_QUANTITY_CHANGE,
    payload: {
        quantity: quantity,
        itemIndex: itemIndex
    }
})

export const changeComplimentaryStatusAction = (itemIndex, isComplimentary) => ({
    type: CHANGE_COMPLIMENTARY_STATUS,
    payload: {
        isComplimentary: isComplimentary,
        itemIndex: itemIndex
    }
})

export const changeUpSellStatusAction = (itemIndex, isUpsell) => ({
    type: CHANGE_UPSELL_STATUS,
    payload: {
        isUpsell: isUpsell,
        itemIndex: itemIndex
    }
})

export const changeFoodViewAction = (foodMenuView) => ({
    type: CHANGE_FOOD_VIEW_STATUS,
    payload: {
        foodMenuView: foodMenuView
    }
})

export const changeDrinkViewAction = (drinkMenuView) => ({
    type: CHANGE_DRINK_VIEW_STATUS,
    payload: {
        drinkMenuView: drinkMenuView
    }
})

export const changeCategoryViewAction = (categoryMenuView) => ({
    type: CHANGE_CATEGORY_VIEW_STATUS,
    payload: {
        categoryMenuView: categoryMenuView
    }
})

export const changeCategoryVisibilityAction = (categoryMenuVisibility) => ({
    type: CHANGE_CATEGORY_VISIBILITY_STATUS,
    payload: {
        categoryMenuVisibility: categoryMenuVisibility
    }
})

export const changeCategoryExclusivityAction = (categoryMenuExclusivity) => ({
    type: CHANGE_CATEGORY_EXCLUSIVITY_STATUS,
    payload: {
        categoryMenuExclusivity: categoryMenuExclusivity
    }
})

export const changeEventsCategoryAction = (events) => ({
    type: CHANGE_EVENTS_CATEGORY_STATUS,
    payload: {
        events: events
    }
})

export const changePickupTimeAction = (optionIndex, key, title, builderType) => ({
    type: ITEM_PICKUP_TIME_CHANGE,
    payload: {
        optionIndex: optionIndex,
        key: key,
        title: title,
        pickupTime: title,
        builderType: builderType
    }
})

export const changeItemDescriptionAction = (itemIndex, description) => ({
    type: types.ITEM_DESCRIPTION_CHANGE,
    payload: {
        description: description,
        itemIndex: itemIndex
    }
})

export const changeItemDiscountUnitAction = (optionIndex, key, title, builderType) => ({
    type: ITEM_DISCOUNT_UNIT_CHANGE,
    payload: {
        optionIndex: optionIndex,
        key: key,
        title: title,
        discountUnit: title,
        builderType: builderType
    }
})

export const changeItemDiscountValueAction = (itemIndex, discountValue) => ({
    type: ITEM_DISCOUNT_VALUE_CHANGE,
    payload: {
        itemIndex: itemIndex,
        discountValue: discountValue
    }
})

export const addMenuItemAction = (eventCategory) => ({
    type: ADD_MENU_ITEM,
    payload: {eventCategory},
})

export const changeItemAvailabilityAction = (itemIndex, isAvailable) => ({
    type: ITEM_AVAILABLE_CHANGE,
    payload: {
        isAvailable: isAvailable,
        itemIndex: itemIndex
    }
})

export const changeOptionValueAction = (optionIndex, valueIndex, key, value, builderType) => ({
    type: OPTION_VALUE_CHANGE,
    payload: {
        optionIndex: optionIndex,
        valueIndex: valueIndex,
        key: key,
        value: value,
        builderType: builderType
    }
})

export const changeOptionTitleAction = (optionIndex, key, title, builderType) => ({
    type: OPTION_TITLE_CHANGE,
    payload: {
        optionIndex: optionIndex,
        key: key,
        title: title,
        builderType: builderType
    }
})

export const changeOptionOnTabletAction = (optionIndex, builderType) => ({
    type: types.CHANGE_OPTION_ON_TABLET,
    payload: {
        optionIndex: optionIndex,
        builderType: builderType
    }
})

export const addMenuOptionAction = () => ({
    type: ADD_MENU_OPTION,
    payload: {},
})

export const addDefaultOptionAction = () => ({
    type: types.ADD_DEFAULT_OPTION,
    payload: {},
})

export const addMenuValueAction = (optionIndex, builderType) => ({
    type: ADD_MENU_VALUE,
    payload: {
        optionIndex: optionIndex,
        builderType: builderType
    }
})

export const changeItemImageAction = (image) => ({
    type: ITEM_IMAGE,
    payload: {
        image: image
    }
})

export const deleteItemAction = (index) => ({
    type: DELETE_MENU_ITEM,
    payload: {
        index: index
    }
})

export const deleteOptionAction = (index, builderType) => ({
    type: DELETE_MENU_OPTION,
    payload: {
        index: index,
        builderType: builderType
    }
})

export const deleteValueAction = (index, valueIndex, builderType) => ({
    type: DELETE_MENU_VALUE,
    payload: {
        optionIndex: index,
        valueIndex: valueIndex,
        builderType: builderType
    }
})

export const emptyToDeleteAction = () => ({
    type: EMPTY_TO_DELETE,
    payload: {
        items: [],
        options: [],
        values: [],
        defaultOptions: []
    }
})

export const emptyToDeleteModifiersAction = () => ({
    type: types.EMPTY_TO_DELETE_MODIFIERS,
    payload: {
        options: [],
        values: [],
    }
})

export const dragItemAction = (result, hoverCategory) => ({
    type: ON_DRAG_ITEM,
    payload: {
      result: result,
      hoverCategory: hoverCategory
    }
})

export const dragOptionAction = (result) => ({
    type: ON_DRAG_OPTION,
    payload: result
})

export const copyItemAction = (index) => ({
    type: COPY_ITEM,
    payload: {
        index: index
    }
})

export const pasteItemAction = (index) => ({
    type: PASTE_ITEM,
    payload: {
        index: index
    }
})

export const copyOptionAction = (index) => ({
    type: COPY_OPTION,
    payload: {
        index: index
    }
})

export const pasteOptionAction = (index) => ({
    type: PASTE_OPTION,
    payload: {
        index: index
    }
})

export const makeOptionNonDefaultAction = (index) => ({
    type: types.MAKE_OPTION_NON_DEFAULT,
    payload: {
        index: index
    }
})

export const copyValueAction = (index, valueIndex) => ({
    type: COPY_VALUE,
    payload: {
        index: index,
        valueIndex: valueIndex
    }
})

export const pasteValueAction = (index, valueIndex) => ({
    type: PASTE_VALUE,
    payload: {
        index: index,
        valueIndex: valueIndex
    }
})

export const moveItemAction = (index) => ({
    type: MOVE_ITEM,
    payload: index
})

export const validateMenuAction = (onValidate) => ({
    type: VALIDATE_MENU,
    payload: null,
    onValidate: onValidate,
})

export const getDefaultOptionsAction = (type, paramObj, onSuccess, onFailure) => ({
    type: type,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_default_options',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const updateDefaultOptionsAction = (shopId, optionsJson, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: optionsJson,
    meta: {
        type: 'api',
        url: baseUrl + '/update_default_options',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(optionsJson)
        }
    }
})

export const validateDefaultModifiersAction = (onValidate) => ({
    type: types.VALIDATE_DEFAULT_MODIFIERS,
    payload: null,
    onValidate: onValidate,
})

export const newCategoryAction = (categoryHeader, membersOnly, membersOnlyVisible, events) => ({
    type: NEW_CATEGORY,
    payload: {
        categoryHeader: categoryHeader,
        membersOnly: membersOnly,
        membersOnlyVisible: membersOnlyVisible,
        events: events
    }
})

export const editCategoryAction = (oldCategoryHeader, newCategoryHeader) => ({
    type: EDIT_CATEGORY,
    payload: {
        oldCategoryHeader: oldCategoryHeader,
        newCategoryHeader: newCategoryHeader
    }
})

export const dragCategoryAction = (result) => ({
    type: ON_DRAG_CATEGORY,
    payload: result
})

export const deleteCategoryAction = (categoryHeader) => ({
    type: DELETE_CATEGORY,
    payload: {
        categoryHeader: categoryHeader
    }
})
