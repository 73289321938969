// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const GET_ALL_SHOPS = "get_all_shops";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";

//Shop const

export const NOT_IN_STORE = "not_in_store";
export const SHOP_LOGIN = "shop_login";
export const SET_SELECTED_SHOP = "set_selected_shop";

export const GET_SHOP_USERS = "get_shop_users";

export const SHOP_SIGNUP = "shop_signup";
export const GET_SHOP = "get_shop";
export const GET_SHOP_INFO = "get_shop_info";
export const GET_SHOP_USER = "get_shop_user";

//Shop Membership const
export const GET_SHOP_MEMBERSHIP = "get_shop_membership";

export const GET_SHOP_MEMBERSHIPS = "get_shop_memberships";

export const GET_MEMBERSHIPS_FOR_ITEM = "get_memberships_for_item";
export const GET_MEMBER_UPDATES = "get_member_updates";

//Menu const
export const GET_SHOPS_DATABASE = "get_shops_database";
export const DELETE_MENU_ITEM = "delete_menu_item";
export const DELETE_MENU_OPTION = "delete_menu_option";
export const DELETE_MENU_VALUE = "delete_menu_value";
export const ITEM_NAME_CHANGE = "item_name_change";
export const ITEM_QUANTITY_CHANGE = "item_quantity_change";
export const ITEM_PICKUP_TIME_CHANGE = "item_pickup_time_change";
export const ITEM_DISCOUNT_UNIT_CHANGE = "item_discount_unit_change";
export const ITEM_DISCOUNT_VALUE_CHANGE = "item_discount_value_change";
export const ITEM_AVAILABLE_CHANGE = "item_available_change";
export const ADD_MENU_ITEM = "add_menu_item";
export const OPTION_VALUE_CHANGE = "option_value_change";
export const OPTION_TITLE_CHANGE = "option_title_change";
export const ADD_MENU_OPTION = "add_menu_option";
export const ADD_MENU_VALUE = "add_menu_value";
export const ITEM_IMAGE = "item_image";
export const EMPTY_TO_DELETE = "empty_to_delete";
export const ON_DRAG_ITEM = "on_drag_item";
export const ON_DRAG_OPTION = "on_drage_option";
export const COPY_ITEM = "copy_item";
export const PASTE_ITEM = "paste_item";
export const COPY_OPTION = "copy_option";
export const PASTE_OPTION = "paste_option";
export const COPY_VALUE = "copy_value";
export const PASTE_VALUE = "paste_value";
export const VALIDATE_MENU = "validate_menu";
export const MOVE_ITEM = "move_item";
export const UPDATE_DEFAULT_MODIFIER_INDEX = "UPDATE_DEFAULT_MODIFIER_INDEX";
export const CHANGE_COMPLIMENTARY_STATUS = "change_complementary_status";
export const CHANGE_UPSELL_STATUS = "change_upsell_status";
export const CHANGE_DRINK_VIEW_STATUS = "change_drink_view_status";
export const CHANGE_FOOD_VIEW_STATUS = "change_food_view_status";
export const CHANGE_CATEGORY_VIEW_STATUS = "change_category_view_status";
export const CHANGE_CATEGORY_VISIBILITY_STATUS =
  "change_category_visibility_status";
export const CHANGE_CATEGORY_EXCLUSIVITY_STATUS =
  "change_category_exclusivity_status";
export const CHANGE_EVENTS_CATEGORY_STATUS = "change_events_category_status";

export const UPDATE_ITEM_INDEX = "update_item_index";
export const DRINK_OR_FOOD = "drink_or_food";
export const FIRST_MENU_LOAD = "first_menu_load";
export const EDIT_CATEGORY = "edit_category";
export const NEW_CATEGORY = "new_category";
export const DELETE_CATEGORY = "delete_category";
export const ON_DRAG_CATEGORY = "on_drag_category";

export const types = {
  ADD_DEFAULT_OPTION: "add_default_option",
  MENU_BUILDER: "MENU_BUILDER",
  DEFAULT_MODIFIERS_BUILDER: "DEFAULT_MODIFIERS_BUILDER",
  GET_DEFAULT_OPTIONS: "GET_DEFAULT_OPTIONS",
  VALIDATE_DEFAULT_MODIFIERS: "VALIDATE_DEFAULT_MODIFIERS",
  EMPTY_TO_DELETE_MODIFIERS: "EMPTY_TO_DELETE_MODIFIERS",
  GET_DEFAULT_OPTIONS_MENU: "GET_DEFAULT_OPTIONS_MENU",
  DELETE_DEFAULT_OPTION: "DELETE_DEFAULT_OPTION",
  MAKE_OPTION_NON_DEFAULT: "MAKE_OPTION_NON_DEFAULT",
  FIRST_DEFAULT_MODIFIERS: "FIRST_DEFAULT_MODIFIERS",
  SHOW_ITEM_DESCRIPTION: "SHOW_ITEM_DESCRIPTION",
  ITEM_DESCRIPTION_CHANGE: "ITEM_DESCRIPTION_CHANGE",
  CHANGE_OPTION_ON_TABLET: "CHANGE_OPTION_ON_TABLET",
  GET_CHAIN_SHOPS: "GET_CHAIN_SHOPS",
  SHOW_ITEM_DISCOUNT: "SHOW_ITEM_DISCOUNT",
};

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";

// Analytics Constants
export const analyticsConstants = {
  GET_ADDON_SALES_DATA: "get_addon_sales_data",
  GET_TOP_FIVE_ADDON_SALES: "get_top_five_addon_sales",
  GET_ITEM_SALES_DATA: "get_item_sales",
  GET_TOP_FIVE_ITEM_SALES: "top_five_item_sales",
  GET_BEST_SELLERS: "best_sellers",
  GET_GROSS_ORDERS: "gross_orders",
  GET_UNIQUE_CUSTOMERS: "unique_customers",
  GET_NUM_ORDERS: "num_orders",
  GET_RECENT_ORDERS: "recent_orders",
  GET_AVERAGE_TRANSACTION: "average_transaction",
  GET_NEW_CUSTOMERS: "get_new_customers",
  GET_GROSS_SALES_TODAY: "get_gross_sales_today",
  GET_CUSTOMER_RECENCY_DAY: "get_customer_recency_day",
  GET_CUSTOMER_RECENCY_WEEK: "get_customer_recency_week",
  GET_CUSTOMER_RECENCY_MONTH: "get_customer_recency_month",
  GET_CUSTOMER_RECENCY_YEAR: "get_customer_recency_year",
  GET_CUSTOMER_FREQ_FIVE: "get_customer_freq_five",
  GET_CUSTOMER_FREQ_FOUR: "get_customer_freq_four",
  GET_CUSTOMER_FREQ_THREE: "get_customer_freq_three",
  GET_CUSTOMER_FREQ_TWO: "get_customer_freq_two",
  GET_CUSTOMER_FREQ_ONE: "get_customer_freq_one",
  GET_GROSS_SALES_RANGE: "get_gross_sales_range",
  GET_GROSS_SALES_HOURS: "get_gross_sales_hours",
  GET_GROSS_TIPS: "get_gross_tips",
  GET_GROSS_TAX: "get_gross_tax",
  GET_GROSS_SALES: "get_gross_sales",
  GET_TABLE_DATE_RANGE_REPORT: "get_table_date_range_report",
  GET_PAYOUT_TABLE_DATA: "get_payout_table_data",
  GET_MEMBERSHIP_STATS: "get_membership_stats",
};
