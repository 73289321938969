//this is the actual button
import React, {Component, useState} from 'react';
import { Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import moveIcon from '../../../../assets/icons/move.PNG';
import moveIconSelected from '../../../../assets/icons/move_rollover.PNG';
import editIcon from '../../../../assets/icons/edit.PNG';
import editIconSelected from '../../../../assets/icons/edit_rollover.PNG';
//import {ReactComponent as MemberOnlyIcon} from '../../../../assets/iconMembership.svg';
import MemberOnlyIcon from './memberOnlyIcon'
import {SortableElement, SortableHandle} from 'react-sortable-hoc';

const MenuTabsButton = ({ itemType, title, isValid, isDrinkOrFood, onToggleFoodDrink, onCategoryEdit, handleCategoryHover, isMemberOnly}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isDragSelected, setIsDragSelected] = useState(false);
    const [isEditSelected, setIsEditSelected] = useState(false);

    const setHoverCategory = (isHoveredOver) => {
        setIsHovered(isHoveredOver)
        handleCategoryHover(isHoveredOver ? title : "**_noCategory_**")
    }

    const DragHandle = SortableHandle(() =>
        <img src={isDragSelected ? moveIconSelected : moveIcon} alt="move"
             onMouseEnter={() => {
                 setHoverCategory(true)
                 setIsEditSelected(true)
             }}
            style={{
                position : 'absolute',
                left: 9,
                marginBottom: 5,
                zIndex: 9,
                height: 21,
                width: 21,
            }}
        />
    );
    const CategoryEdit = () => {
        return (
          <img src={isEditSelected ? editIconSelected : editIcon} alt="move"
              onMouseEnter={() => {
                  setHoverCategory(true)
                  setIsEditSelected(true)
              }}
              style={{
                  position : 'absolute',
                  right: 9,
                  marginBottom: 5,
                  zIndex: 9,
                  height: 21,
                  width: 21,
              }}
              onClick={() => onCategoryEdit(title)}
          />
        )
    }

    return (<span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {isHovered &&
                    <DragHandle/>
                }
                {isHovered &&
                    <CategoryEdit/>
                }
                <Button variant={isDrinkOrFood == itemType? 'contained' : 'outlined'}
                        size="large"
                        color="primary"
                        style={styles.foodDrinkButton}
                        onClick={() => onToggleFoodDrink(itemType)}
                        className='col-12'
                        onMouseEnter={() => {
                            setHoverCategory(true)
                        }}
                        onMouseLeave={() => {
                            setHoverCategory(false)
                        }}>
                    {isMemberOnly &&
                        <div style={{margin: 5}}>
                          <MemberOnlyIcon/>
                        </div>
                    }
                    {title}
                </Button>
                { (isValid == undefined || isValid)? null : <ErrorIcon style={styles.error}/>}
            </span>)
}

const styles = {
    foodDrinkButton: {
        marginBottom: 5,
    },
    error: {
        position: 'absolute',
        color: '#ff0000',
        marginRight: -80,
        marginTop: -10,
        zIndex: 10
    }
}

export default MenuTabsButton
