import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import IntlMessages from "../../util/IntlMessages";
import CustomScrollbars from '../../util/CustomScrollbars';
import {isShopChainAction, isShopInChainAction} from '../../actions/ManageShops';
import { getShopUserAction } from '../../actions/ShopInfo';
import selectors from '../../reducers/selectors';
import {getMembershipAction} from "../../actions/Membership";

class SidenavContent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isChain: false,
            isInChain: false,
            membershipEnabled: true,
            showMembershipNav: true
        }
    }
    componentDidMount() {
        const { history } = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            }
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
        this.props.isShopInChainAction(this.props.shopId,
            (resp) => {
                if (resp === "True") {
                    this.setState({ isInChain: true });
                }
            })
        this.props.isShopChainAction(this.props.shopId,
            (resp) => {
                if (resp === "True") {
                    this.setState({ isChain: true });
                }
            })

        this.props.getShopUserAction(this.props.shopId,
            (resp) => {
                this.setState({ membershipEnabled: resp.membershipEnabled, showMembershipNav: resp.membershipEnabled });

                if(resp.membershipId){
                    this.props.getMembershipAction(resp.membershipId, this.props.shopId,
                        (memberResp) => {
                            // console.log('Response', resp)
                            if(memberResp.val !== false) {
                                if(this.state.membershipEnabled) {
                                    if(memberResp.isGlobalMembership && resp.isChainShop) {
                                        this.setState({showMembershipNav: true});
                                    } else if(memberResp.isGlobalMembership && !resp.isChainShop) {
                                        this.setState({showMembershipNav: false});
                                    }
                                } else this.setState({showMembershipNav: false});
                            } else this.setState({showMembershipNav: false});
                        },
                        (error) => {
                            console.log("get shop membership error", error)
                        })
                }
            })


    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className=" scrollbar">
                <ul className="nav-menu">

                    <li className="nav-header"><IntlMessages id="sidebar.main" /></li>

                    <li className="menu collapse-box">
                        <Button href="javascript:void(0)">
                            <i className="zmdi zmdi-trending-up zmdi-hc-fw" />
                            <span className="nav-text">
                                Analytics
                            </span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/app/overview">
                                    <span className="nav-text">Overview</span>
                                </NavLink>
                            </li>
                            {this.props.permissions.analytics &&
                            <>
                            <li>
                                <NavLink to="/app/payouts">
                                    <span className="nav-text">Payouts (Under Construction)</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/app/analytics">
                                    <span className="nav-text">Transactions (Under Construction)</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/app/sales_overview">
                                    <span className="nav-text">Sales</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/app/item_sales">
                                    <span className="nav-text">Item Sales</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/app/modifier_sales">
                                    <span className="nav-text">Modifier Sales</span>
                                </NavLink>
                            </li></>}
                        </ul>
                    </li>
                    {this.state.isChain && this.props.permissions.shopManagement ?
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage_shops">
                                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                                <span className="nav-text">Manage Shops</span>
                            </NavLink>
                        </li>
                        :
                        <div />
                    }
                    {this.props.permissions.shopManagement && <li className="menu no-arrow">
                        <NavLink to="/app/shop_info">
                            <i className="zmdi zmdi-book-image zmdi-hc-fw" />
                            <span className="nav-text">Shop Info</span>
                        </NavLink>
                    </li>}
                    {!this.state.isInChain && this.state.showMembershipNav && this.props.permissions.membership ?
                        <li className="menu collapse-box">
                            <Button href="javascript:void(0)">
                                <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                                <span className="nav-text">Membership</span>
                            </Button>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink className="prepend-icon" to="/app/manage_memberships">
                                        <span className="nav-text">Manage Memberships</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/app/members">
                                        <span className="nav-text">Members</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/app/membership_stats">
                                        <span className="nav-text">Membership Stats</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        :
                        <div />
                    }
                    {this.props.permissions.menuBuilding && <li className="menu collapse-box">
                        <Button href="javascript:void(0)">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">Menu</span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/app/default-modifiers">
                                    <span className="nav-text">Default Modifiers</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/app/menu">
                                    <span className="nav-text">Menu Builder</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>}
                    {this.props.permissions.menuBuilding && <li className="menu collapse-box">
                        <Button href="javascript:void(0)">
                            <i className="zmdi zmdi-label zmdi-hc-fw" />
                            <span className="nav-text">Promo Code</span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/app/promo-code-list">
                                    <span className="nav-text">Promo Code List</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/app/promo-code-usage">
                                    <span className="nav-text">Promo Code Usage</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/app/promo-code">
                                    <span className="nav-text">Create Promo Code</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>}
                    {this.state.showMembershipNav && this.props.permissions.membership ?
                        <li className="menu collapse-box">
                            <Button href="javascript:void(0)">
                                <i className="zmdi zmdi-notifications" />
                                <span className="nav-text">Announcements</span>
                            </Button>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink className="prepend-icon" to="/app/announcements">
                                        <span className="nav-text">New</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/app/announcementHistory">
                                        <span className="nav-text">History</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        :
                        <div />
                    }
                    {this.props.permissions.userManagement && <li className="menu no-arrow">
                        <NavLink to="/app/users">
                            <i className="zmdi zmdi zmdi-accounts-outline" />
                            <span className="nav-text">Manage Users</span>
                        </NavLink>
                    </li>}
                </ul>
            </CustomScrollbars>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        shopId: selectors(state).shopId,
        permissions: state.auth.selectedPermissions
    }
}

const actionCreators = {
    isShopChainAction,
    getShopUserAction,
    getMembershipAction,
    isShopInChainAction
}

export default withRouter(connect(mapStateToProps, actionCreators)(SidenavContent))
