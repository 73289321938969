import  {NOT_IN_STORE}  from "../constants/ActionTypes";
import { baseUrl } from "../constants/baseUrl";

export const verifyEmailAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/verify_email',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            email: paramObj.email,
            token: paramObj.token,
        },
        options: {
            method: 'POST'
        }
    }
})

export const verifyShopUserEmailAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/verify_staff_email',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            email: paramObj.email,
            token: paramObj.token,
            password: paramObj.password
        },
        options: {
            method: 'POST'
        }
    }
})
