import React from 'react';
import './overviewStyle.css'
import { connect } from 'react-redux'
import selectors from '../reducers/selectors';
import { getShopInfoAction } from '../actions/ShopInfo';

class AdminOverview extends React.Component {

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            menuState: false,
        }
    }

    render() {
        const { anchorEl, menuState } = this.state;
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <h1>Welcome to CoffeePass Admin Section</h1>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shop: selectors(state).shop,
        shopId: selectors(state).shopId,
        permissions: state.auth.selectedPermissions
    }
}

const actionCreators = {
    getShopInfoAction
}

export default connect(mapStateToProps, actionCreators)(AdminOverview)
