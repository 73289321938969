import React, {Component} from 'react';
import {connect} from 'react-redux'
import Option from '../MenuBuilder/Option';
import { Button } from '@material-ui/core';
import { removeDeletedMenuAction, changeItemImageAction, updateShopMenuV3Action, getShopMenuAction,
    updateDefaultOptionsAction, getDefaultOptionsAction, validateDefaultModifiersAction, emptyToDeleteModifiersAction, emptyToDeleteAction, validateMenuAction } from '../../../../actions/Menu';
import { firstMenuLoadAction, updateDrinkOrFoodAction, updateCurrentItemAction, firstDefaultModifiersLoadAction } from '../../../../actions/MenuBuilder'
import { NotificationManager, NotificationContainer } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MixpanelTrack } from '../../../../util/MixpanelHelper';
import CustomScrollbars from '../../../../util/CustomScrollbars';
import DefaultList from './DefaultList'
import { types } from '../../../../constants/ActionTypes';
import SaveSureDialog from '../../ShopInfo/SaveSureDialog';
import selectors from '../../../../reducers/selectors';

class DefaultModifiers extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedImage: null,
            isDialogOpen: false,
            isSaveDialogOpen: false,
            isLoadingSave: false
        }

        this.closeDialog = this.closeDialog.bind(this)
    }

    componentDidMount() {
        if(this.props.menuUI.isFirstDefaultModifiers) {
            this.props.getDefaultOptionsAction(types.GET_DEFAULT_OPTIONS,
                {
                    shopId: this.props.shopId
                })
            this.props.firstDefaultModifiersLoadAction()
        }

        this.props.getShopMenuAction({
            shopId: this.props.shopId,
            menuId: null
        })
        // MixpanelTrack("Visited Default Modifiers Page")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.shopId !== this.props.shopId) {
            this.props.getDefaultOptionsAction(types.GET_DEFAULT_OPTIONS,
                {
                    shopId: this.props.shopId
                })
            this.props.getShopMenuAction({
                shopId: this.props.shopId,
                menuId: null
            })
        }
    }

    onToggleFoodDrink = (value) => {
        this.props.updateDrinkOrFoodAction(value)
        MixpanelTrack('Clicked Tab ' + value)
    }

    onClickImage = (itemIndex, image) => {
        this.props.updateCurrentItemAction(itemIndex)
        this.setState({
            selectedImage: image,
            isDialogOpen: true
        })
    }

    onSaveClicked = () => {
        this.setState({
            isSaveDialogOpen: true
        })
        MixpanelTrack('Clicked Save Default Options')
    }

    handleSaveDialogClose = () => {
        this.setState({isSaveDialogOpen: false})
    }

    onDialogYesClicked = () => {
        MixpanelTrack('Save Default Modifiers Confirmed')
        this.handleSaveDialogClose()
        this.setState({isLoadingSave: true})
        this.props.validateDefaultModifiersAction((isValid) => {
            if(isValid) {
                this.props.updateDefaultOptionsAction(this.props.shopId, this.props.defaultModifiers,
                    (resp) => {
                        if(resp == "True") {
                            NotificationManager.success("The new Default Modifiers are now saved!", "Success")
                        } else {
                            NotificationManager.error("There was a problem saving the modifiers! If the problem persists contact support@coffeepass.io.", "Error Saving Modifiers!", 9000)
                        }
                        // this.setState({isLoadingSave: false})
                        this.saveMenu()
                    },
                    (error) => {
                        NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Modifers!", 9000)
                        this.setState({isLoadingSave: false})
                    })
            } else {
                this.setState({isLoadingSave: false})
                NotificationManager.error("Make sure all fields are filled in the Menu. Fix the errors and save again.", "Invalid Menu", 5000)
            }
        })


    }

    //TODO: this whole chain of rest endpoints is pretty ugly, will want to refactor this eventually
    saveMenu = () => {
        //save the menu as well
        this.props.validateMenuAction((isValid) => {
            if(isValid) {
                this.props.updateShopMenuV3Action(this.props.menu,
                    (resp) => {
                        this.setState({isLoadingSave: false})
                        //things hae to be removed after the menu is saved
                        //this removes the items, options, and values from the database that were deleted
                        this.props.removeDeletedMenuAction(this.props.toDeleteModifiers,
                            (resp) => {
                                if(resp == "True") {
                                    // given below endpoint call added specialy for the case when a new DefaultOption is saved,
                                    // that's why fetching DefaultOptions data so that menuOptionId gets refreshed from db as integer instead of guid
                                    this.props.getDefaultOptionsAction(types.GET_DEFAULT_OPTIONS,
                                        {
                                            shopId: this.props.shopId
                                        })

                                    //Get the shop menu so it has the new default modifiers
                                    this.props.getShopMenuAction({
                                        shopId: this.props.shopId,
                                        menuId: null
                                    })
                                    this.props.emptyToDeleteModifiersAction()
                                } else {
                                    NotificationManager.error("There was a problem removing items from the menu! If the problem persists contact support@coffeepass.io.", "Error removeing Items!", 9000)
                                }
                            },
                            (error) => {
                                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Menu!", 9000)
                            })

                    },
                    (error) => {
                        NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Menu!", 9000)
                        this.setState({isLoadingSave: false})
                    })

                //this removes the items, options, and values from the database that were deleted
                this.props.removeDeletedMenuAction(this.props.toDelete,
                    (resp) => {
                        if(resp == "True") {
                            this.props.emptyToDeleteAction()
                            // NotificationManager.success("The new shop menu is now in CoffeePass!", "Success")
                        } else {
                            NotificationManager.error("There was a problem removing items from the menu! If the problem persists contact support@coffeepass.io.", "Error removeing Items!", 9000)
                        }
                    },
                    (error) => {
                        NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Menu!", 9000)
                    })
            } else {
                this.setState({isLoadingSave: false})
                NotificationManager.error("Make sure all fields are filled in the Menu. Fix the errors and save again.", "Invalid Menu", 5000)
            }
        })
    }

    closeDialog(image) {
        this.props.changeItemImageAction(image)
        this.props.updateCurrentItemAction(null)
        this.setState({
            isDialogOpen: false
        })
    }

    sideBar = () => {
        return <div className="module-side">
            <div className="module-side-header">
                <div className="module-logo">
                    <i className="zmdi zmdi-view-dashboard mr-4"/>
                    <span>Default Modifiers</span>
                </div>
            </div>
            <div className="module-side-content">
                <DefaultList width={this.props.width} />
            </div>
        </div>
    }

    render() {
        if(this.props.menuUI.currentItemIndex !== null)
            this.itemName = this.props.menu.categories[this.props.menuUI.drinkOrFood]['items'][this.props.menuUI.currentItemIndex].name
        return (
            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                    <div className="app-module">
                        <div className="app-module-sidenav d-none d-xl-flex">
                            {this.sideBar()}
                        </div>

                        <div className="module-box">
                            <div className="module-box-header">

                            </div>
                            <div className="row">
                                <div className="col-md-7 col-12">
                                    {this.props.defaultModifiers.length == 0?
                                        <h2 style={styles.textContainer}>Add a Default Modifier to get started!</h2>
                                        :
                                        <CustomScrollbars className="module-list-scroll scrollbar"
                                                style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                                            {this.props.defaultModifiers[this.props.selectedIndex] ?
                                                <Option
                                                    i={this.props.selectedIndex}
                                                    showValues={true}
                                                    onShowValues={null}
                                                    option={this.props.defaultModifiers[this.props.selectedIndex]}
                                                    builderType={types.DEFAULT_MODIFIERS_BUILDER}/>
                                                :
                                                <div/>
                                            }
                                        </CustomScrollbars>
                                    }
                                </div>
                                <div className="col-11" style={styles.buttonContainer}>
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        className="jr-btn"
                                        onClick={this.onSaveClicked}>
                                            {this.state.isLoadingSave?
                                                <CircularProgress size={16} style={styles.progress} />
                                                :
                                                "Save"
                                            }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SaveSureDialog
                        isDialogOpen= {this.state.isSaveDialogOpen}
                        handleClose={this.handleSaveDialogClose}
                        onYesClicked={this.onDialogYesClicked}
                        onNoClicked={this.handleSaveDialogClose}
                        dialogDataToSave="menu" />
                </div>
            </div>
        )
    }
}

const styles = {
    itemName: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        marginLeft: 10
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        marginBottom: 10,
        maxHeight: 40
    },
    foodDrinksContainer: {
        margin: 10
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    },
    textContainer: {
        margin: 50,
        marginTop: 200,
        marginBottom: 200
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shopId: selectors(state).shopId,
        menu: state.menu.payload,
        toDeleteModifiers: state.menu.toDeleteModifiers,
        toDelete: state.menu.toDelete,
        menuUI: selectors(state).menuUI,
        shop: selectors(state).shop,
        defaultModifiers: state.menu.defaultModifiers,
        selectedIndex: state.menuBuilder.selectedDefaultIndex
    }
}

const actionCreators = {
    updateDefaultOptionsAction,
    removeDeletedMenuAction,
    getDefaultOptionsAction,
    firstDefaultModifiersLoadAction,
    getShopMenuAction,
    updateCurrentItemAction,
    updateDrinkOrFoodAction,
    changeItemImageAction,
    emptyToDeleteModifiersAction,
    validateDefaultModifiersAction,
    validateMenuAction,
    emptyToDeleteAction,
    updateShopMenuV3Action
}

export default connect(mapStateToProps, actionCreators)(DefaultModifiers)
