import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
const  ShopSelectDialog = ({onLogin, permissions, isDialogOpen, handleClose}) => {
    let [selectedShop, setSelectedShop] = useState("select")
    const handleLogin = () => {
        if(selectedShop !== "select") {
            onLogin(selectedShop)
        }
    }
    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Select a shop to log into"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormControl variant="outlined">
                        <Select
                            variant="outlined"
                            value={selectedShop}
                            onChange={(e) => setSelectedShop(e.target.value)}
                        >
                            <MenuItem value= {"select"}>Select a Shop</MenuItem>
                            {permissions.map(s => <MenuItem key={s.shop_id} value= {s.shop_id}>{s.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleLogin()}>
                    Select Shop
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShopSelectDialog;
