import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import CardBox from '../analytics/util/CardBox';
import UsersDataTable from './UsersDataTable';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import { NotificationContainer } from 'react-notifications';
import selectors from '../../../reducers/selectors';
import {addShopUser, getShopUsersAction, setShopUserPermissionsAction} from '../../../actions/ShopInfo';
import {getAllShopsInChainAction} from "../../../actions/ManageShops";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserPermissionDialog from "./UserPermissionDialog";
import UserAddDialog from "./UserAddDialog";
import Button from "@material-ui/core/Button";

class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shopID: this.props.shopId,
            isLoading: false,
            page: 0,
            loading: false,
            showPermissionDialog: false,
            selectedUserPermissions: null,
            selectedUserId: null,
            showAddDialog: false
        };

        this.onSelectUser = this.onSelectUser.bind(this);
        this.savePermissions = this.savePermissions.bind(this);
        this.addUser = this.addUser.bind(this);
    }
    componentDidMount() {
        MixpanelTrack("Visited Users Page")
        this.setState({isLoading: true});
        this.props.getAllShopsInChainAction(this.props.shopId, async (result) => {
            let shopIds = result.map(s => s.shopId);
            this.props.getShopUsersAction(shopIds, () => this.setState({isLoading: false}));
        });

    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        MixpanelTrack('Change to analytics page ' + page)
    }

    formatUserData(data) {
        let arrayResult = [
            { value: data.firstName, numeric: false },
            { value: data.lastName, numeric: false },
            { value: data.email, numeric: false },
            { value: data, numeric: false }
        ];
        return arrayResult;
    }

    async savePermissions(newPermissions) {
        this.props.setShopUserPermissionsAction({shop_login_id: this.state.selectedUserId, body: newPermissions}, (result) => {
            this.setState({showPermissionDialog: false, selectedUserPermissions: null, selectedUserId: null, isLoading: true});
            let shopIds = this.props.shopsInChain.map(s => s.shopId);
            this.props.getShopUsersAction(shopIds, () => this.setState({isLoading: false}));
        });
    }

    async addUser(body) {
        this.props.addShopUser(body, (result) => {
            if(result.success) {
                this.setState({showAddDialog: false, isLoading: true});
                let shopIds = this.props.shopsInChain.map(s => s.shopId);
                this.props.getShopUsersAction(shopIds, () => this.setState({isLoading: false}));

            }
        });
    }

    onSelectUser(id) {
        const userPrivileges = this.props.shopUsers.find(u => u.shopLoginId === id).shopPrivileges;
        this.setState({showPermissionDialog: true, selectedUserPermissions: userPrivileges, selectedUserId: id});
    }

    render() {
        const columnData = [
            { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
            { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
        ];
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Users"} match={this.props.match} />
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button color="primary" onClick={() => this.setState({showAddDialog: true})}>
                        + Add User
                    </Button>
                </div>
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    {this.state.isLoading?
                        <div style={styles.progress} >
                            <CircularProgress size={50} />
                        </div>
                        :
                        <UsersDataTable
                            title="User List"
                            data={this.props.shopUsers.filter(u => u.shopLoginId !== this.props.userId)}
                            dataFormatter={this.formatUserData}
                            columnData={columnData}
                            isLoading={this.state.isLoading}
                            onChangePage={this.handleChangePage}
                            page={this.state.page}
                            onAction= {this.onSelectUser}
                        />}
                </CardBox>
                <NotificationContainer />
                <UserPermissionDialog isDialogOpen={this.state.showPermissionDialog} handleClose={() => this.setState({showPermissionDialog: false, selectedUser: null})} shops={this.props.shopsInChain} onSave={this.savePermissions} userPermissions={this.state.selectedUserPermissions}/>
                <UserAddDialog isDialogOpen={this.state.showAddDialog} handleClose={() => this.setState({showAddDialog: false})} shops={this.props.shopsInChain} onSave={this.addUser}/>

            </div>)
    }
}

const styles = {
    progress: {
        display: 'flex',
        margin: 200,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        shopUsers: state.shop.shopUsers,
        shopsInChain: state.manageShops.shops,
        userId: state.auth.userId
    }
}
const actionCreators = {
    getShopUsersAction,
    getAllShopsInChainAction,
    setShopUserPermissionsAction,
    addShopUser
}

export default connect(
    mapStateToProps,
    actionCreators
)(UserList);
