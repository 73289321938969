import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import CardBox from '../analytics/util/CardBox';
import MembershipsDataTable from './MembershipsDataTable';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import { getShopUserAction } from '../../../actions/ShopInfo';
import {
    updateUserMembershipStatusAction,
    deactivateUserMembershipAction,
    getMembershipsAction
} from '../../../actions/Membership';
import ReactExport from "react-data-export";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Membership from "../ShopInfo/Membership";
import {isShopChainAction} from "../../../actions/ManageShops";

class MembershipList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shopID: this.props.shopId,
            isLoading: false,
            membershipEnabled: false,
            membershipId: 0,
            memberships: [],
            page: 0,
            buttonsDisabled: false,
            membershipDetailsOpen: false,
            selectedMembership: null,
            isNewMembership: false
        };

    this.onEditMembership = this.onEditMembership.bind(this);
    }
    componentDidMount() {
        MixpanelTrack("Visited Analytics Page");
        this.props.getMembershipsAction(this.props.shopId);
        this.props.isShopChainAction(this.props.shopId, (resp) => this.setState({isChainLogin: resp === "True"}))
    }

    formatMembershipData(data) {
        let arrayResult = [
            { value: data.membershipName, numeric: false },
            { value: ("$" + data.price.toFixed(2).toString()), numeric: true },
            { value: ((new Date(data.lastUpdatedDate)).toLocaleDateString("en-US")), numeric: false },
            { value: {membershipId: data.membershipId, isCoffeePassMembership: data.isCoffeePassMembership}, numeric: false },
        ];
        return arrayResult;
    }

    onEditMembership(membershipId) {
        this.setState({membershipDetailsOpen: true, selectedMembershipId: membershipId});
    }

    onAddMembership(membershipId) {
        this.setState({membershipDetailsOpen: true, isNewMembership: true});
    }

    getMembershipDialog() {
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <Dialog
                    open={this.state.membershipDetailsOpen}
                    onClose={() => this.setState({membershipDetailsOpen: false, isNewMembership: false, selectedMembershipId: null})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Manage Membership"}</DialogTitle>
                    <DialogContent>
                        <div className="app-login-form">
                            <form method="post" ref={this.formRef} action="/">
                                <div style={{width: '100%'}}>
                                    <Membership isNewMembership={this.state.isNewMembership} shopInfo={this.state.selectedMembership} membershipId={this.state.selectedMembershipId} onClose={() => {
                                        this.setState({membershipDetailsOpen: false, selectedMembershipId: null, isNewMembership: false});
                                        this.props.getMembershipsAction(this.props.shopId);
                                    }}/>
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    render() {
        const columnData = [
            { id: 'membershipName', numeric: false, disablePadding: false, label: 'Membership Name' },
            { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
            { id: 'lastUpdated', numeric: false, disablePadding: false, label: 'Last Updated' },
            { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
        ];
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Manage Memberships"} match={this.props.match} />
                <div style={{display: "flex", justifyContent: "flex-end", marginRight: 10, marginBottom: 10}}><Button variant={"contained"}
                                                                                   color={"primary"}
                                                                                   disabled={this.state.saving}
                                                                                   onClick={() => this.setState({membershipDetailsOpen: true, isNewMembership: true})}>
                    + Add Membership Tier</Button></div>
                <div/>
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    {this.props.memberships.length > 0 &&
                        <MembershipsDataTable
                            title="Membership List"
                            data={this.props.memberships}
                            dataFormatter={this.formatMembershipData}
                            columnData={columnData}
                            isLoading={this.state.isLoading}
                            onChangePage={this.handleChangePage}
                            page={this.state.page}
                            onEditMembership= {this.onEditMembership}
                            selectedMembershipId={this.state.selectedMembershipId}
                            buttonsDisabled={this.state.buttonsDisabled}
                        />
                    }
                </CardBox>
                {this.getMembershipDialog()}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        memberships: state.membership.allMemberships,
        access_token: state.auth.access_token

    }
}
const actionCreators = {
    getShopUserAction,
    getMembershipsAction,
    updateUserMembershipStatusAction,
    deactivateUserMembershipAction,
    isShopChainAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(MembershipList);
