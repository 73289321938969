import queryString from 'query-string'

const apiMiddleware = store => next => action => {
    if (!action.meta || action.meta.type !== 'api') {
        return next(action)
    }
    // This is an api request

    // Find the request URL and compose request options from meta
    const {url} = action.meta;
    var fetchOptions = Object.assign({}, action.meta.options)

    if(!url.includes("s3.amazonaws")) {
        var access_token = store.getState().auth.access_token || localStorage.getItem("access_token");

        fetchOptions = {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                Authorization:
                    'Bearer ' + access_token,

            },
        };
    }


    const finalUrl = url +'?' + queryString.stringify(action.meta.params)

    // Make the request
    fetch(finalUrl, fetchOptions)
    .then(resp => {
        if (resp.status >= 200 && resp.status < 300) {
            return resp
        } else {
            if (typeof action.meta.onFailure === 'function') {
                resp.json().then(json => action.meta.onFailure(json));
                throw new Error("Custom handled error.")
            } else {
                throw new Error("Error on backend");
            }
        }
    })
    .then(resp => {
        resp.text().then(function(respText) {
            if (typeof action.meta.onSuccess === 'function') {
                action.meta.onSuccess(respText)
            }

            // respond back to the user
            // by dispatching the original action without
            // the meta object
            let newAction = Object.assign({}, action, {
                payload: {}
            });
            newAction.payload.val = respText
            delete newAction.meta
            store.dispatch(newAction)
        })

    })
    .catch(error => {
        console.log('error', error)
        if(error.message !== 'Custom handled error.' && typeof action.meta.onFailure === 'function') {
            action.meta.onFailure(error)
        }
    })
}

export default apiMiddleware
