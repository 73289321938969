
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {FormLabel} from "@material-ui/core";

export default class CategoryDialog extends Component { //needs title,
    constructor(props) {
        super(props)

        this.state = {
            categoryHeader : "",
            checkboxValue: false,
            selectorValue: false,
            eventsValue: false
        }
    }

    membersOnlyToggle = () => {
      return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={this.state.checkboxValue}
                    onChange={(event) => {this.setState({checkboxValue: event.target.checked})}}
                    color="primary" />
            }
            label="Members Only" />)
    }

    membersOnlyViewSelect = () => {
      return (
        <FormControl variant="outlined" style={{left: 15}}>
            <FormLabel>Viewable by</FormLabel>
            <Select
                variant="outlined"
                value={this.state.selectorValue}
                onChange={this.handleVisibilityChange}
            >
                <MenuItem value= {true}>Only Members</MenuItem>
                <MenuItem value= {false}>Everyone</MenuItem>
            </Select>
        </FormControl>)
    }

    eventsToggle = () => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.state.eventsValue}
                        onChange={(event) => {this.setState({eventsValue: event.target.checked})}}
                        color="primary" />
                }
                label="Event Category" />)
    }

    handleVisibilityChange = (event) => {
      this.setState({selectorValue: event.target.value})
    };

    clearState() {
        this.setState({categoryHeader : "",
            checkboxValue: false,
            selectorValue: false,
            eventsValue: false});
    }

    render(props) {
        return(
            <Dialog
                open={this.props.isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth>
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="Category Header"
                        label="Category Title"
                        value={this.state.categoryHeader}
                        onChange={(event) => {this.setState({categoryHeader : event.target.value})}}
                        placeholder='Category Title Here'
                        multiline={false}
                        margin="normal"
                        inputProps={{ maxLength: 300 }}
                        fullWidth />
                    {this.state.categoryHeader.length > 20 &&
                    	  <DialogContentText id="alert-dialog-description">
                    		  Categories that are of a length greater than 20 characters may not display correctly in the app.
                    	  </DialogContentText>
                  	}
                </DialogContent>
                <DialogActions style={{height: 100}}>
                    {!this.props.isEdit &&
                            <div style={{left: 15, position: 'absolute', display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    {this.membersOnlyToggle()}
                                    {this.state.checkboxValue && this.membersOnlyViewSelect()}
                                </div>
                                {this.eventsToggle()}
                          </div>}
                    <Button onClick={this.props.onNoClicked} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        this.props.onYesClicked(this.state.categoryHeader, this.state.checkboxValue, this.state.selectorValue, this.state.eventsValue);
                        this.clearState();
                    }} color="primary" autoFocus>
                        Save
                    </Button>
                    {this.props.isEdit &&
                        <Button onClick={this.props.onDeleteClicked} color="primary" autoFocus style={{left: 5, position: 'absolute'}}>
                          Delete
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            )
    }
}

CategoryDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onYesClicked: PropTypes.func.isRequired,
    onNoClicked: PropTypes.func.isRequired,
    onDeleteClicked: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
}
