import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {NotificationContainer, NotificationManager} from "react-notifications";

const UserPermissionDialog = ({isDialogOpen, handleClose, shops, userPermissions, onSave}) => {
    const handleSave = async () => {
        let modifiedPermissions = [];
        shops.forEach(s => {
            let shop_id = s.shopId;
            let shop = {
                shop_id,
                permissions: {
                    analytics: formRef.current[`analytics-${shop_id}`].checked,
                    membership: formRef.current[`membership-${shop_id}`].checked,
                    menuBuilding: formRef.current[`menuBuilding-${shop_id}`].checked,
                    shopManagement: formRef.current[`shopManagement-${shop_id}`].checked,
                    userManagement: formRef.current[`userManagement-${shop_id}`].checked,
                }
            }
            modifiedPermissions.push(shop);
        })

        onSave(modifiedPermissions);
    }
    const formRef = React.createRef();
    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Edit User Permissions"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <form ref={formRef}>
                        {shops.map((s, index) => {
                            let userShopPermissions = userPermissions !== null ? userPermissions.find(shop => shop.shop_id === s.shopId) : undefined;
                            if(userShopPermissions !== undefined) {
                                userShopPermissions = userShopPermissions.permissions;
                            }
                            const checkBoxes = [
                                {
                                    display: "Analytics",
                                    value: "analytics"
                                },
                                {
                                    display: "Shop Management",
                                    value: "shopManagement"
                                },
                                {
                                    display: "Membership",
                                    value: "membership"
                                },
                                {
                                    display: "Menu Building",
                                    value: "menuBuilding"
                                },
                                {
                                    display: "User Management",
                                    value: "userManagement"
                                },


                            ]
                            return (
                                <div style={{display: "flex", flexDirection: "column", marginBottom: 10, minWidth: 400}}>
                                    <h3 style={{textDecoration: "underline"}}>{s.shopName}</h3>
                                    {checkBoxes.map(c =>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={userShopPermissions !== undefined && userShopPermissions[c.value]}
                                                    color="primary"
                                                    name={`${c.value}-${s.shopId}`}
                                                    id={`${c.value}-${s.shopId}`}
                                                />
                                            }
                                            label={c.display}/>
                                    )}

                                </div>)
                        })
                        }
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleSave()}>
                    Save
                </Button>
            </DialogActions>
            <NotificationContainer/>
        </Dialog>
    )
}

export default UserPermissionDialog;
