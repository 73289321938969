import React, { useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../../constants/ActionTypes";
import { Drawer } from "@material-ui/core";
import { AdminSideNavContent } from "./AdminSideNavContent";
import UserInfo from "../../containers/SideNav/UserInfo";
import { withRouter } from "react-router-dom";
import { toggleCollapsedNav, updateWindowWidth } from "../../actions";

const SideNav = ({
  onToggleCollapsedNav,
  updateWindowWidth,
  mobileNavOpen,
  drawerType,
  width,
  navigationStyle,
}) => {
  const [drawerStyle, setDrawerStyle] = useState(
    drawerType.includes(FIXED_DRAWER)
      ? "d-xl-flex"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ""
      : "d-flex"
  );
  const [type, setType] = useState("permanent");

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      updateWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize");
    };
  }, [window]);

  useEffect(() => {
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      setType("temporary");
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      setDrawerStyle("");
      setType("temporary");
    }
  }, [drawerType, navigationStyle]);

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes("temporary") ? mobileNavOpen : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: "side-nav",
        }}
      >
        <UserInfo />
        <AdminSideNavContent />
      </Drawer>
    </div>
  );
};

SideNav.propTypes = {
  onToggleCollapsedNav: PropTypes.func.isRequired,
  updateWindowWidth: PropTypes.func.isRequired,
  mobileNavOpen: PropTypes.bool,
  drawerType: PropTypes.string,
  width: PropTypes.number,
  navigationStyle: PropTypes.string,
};

const mapStateToProps = ({ settings }) => {
  const { drawerType, width, navigationStyle } = settings;
  return { drawerType, width, navigationStyle };
};

export const AdminSideNav = withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
);
