import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
const  SubsidyDialog = ({isDialogOpen, handleClose, complimentaryShops, onSave, shopId, onLoad, optionValueId, menuItemId, optionId}) => {
    const [subsidies, setSubsidies] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(subsidies === null && optionValueId !== null) {
            setLoading(true);
            onLoad(optionValueId, (payload) => {setLoading(false); setSubsidies(payload)}, (error) => {console.log(error); setLoading(false)})
        }
    }, [optionValueId, setLoading, onLoad, setSubsidies])
    const handleSave = async (e) => {
        let subsidies = [];
        e.preventDefault();
        let values = e.target.elements;
        let shops = complimentaryShops.filter(shop => shop.isCoffeePassMembership);

        for(let i = 0; i < shops.length; i++) {
            const membershipName = shops[i].name;
            const amount = parseFloat(values[membershipName].value);
            subsidies.push({
                "menu_item_id": menuItemId,
                "menu_option_id": optionId,
                "option_value_id": optionValueId,
                "shop_id": shopId,
                "membership_id": shops[i].membershipId,
                "amount": amount,
            })
        }
        onSave(subsidies, () => {
            (response) => console.log(response);
            handleClose()
            setSubsidies(null)
        }, () => {
            NotificationManager.error("Error Saving Info!","There was an issue saving... please try again", 5000)
        });
    }
    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Set Subsidy Amounts"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {loading || subsidies === null ?
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                    : <form id={"subsidyForm"} onSubmit={handleSave} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            {complimentaryShops.filter(shop => shop.isCoffeePassMembership).map((s) => {
                        const subsidy = subsidies === null ? undefined : subsidies.find(sub => sub.membershipId === s.membershipId && sub.optionValueId === optionValueId)
                        return  (
                            <FormControl>
                                <InputLabel>{s.name}</InputLabel>
                                <Input
                                    style={{maxWidth: 100}}
                                    type={"number"}
                                    inputProps={{
                                        step: "0.01"
                                    }}
                                    name={s.name}
                                    defaultValue={subsidy !== undefined ? subsidy.amount : 0.00}
                                    color="primary" />
                            </FormControl>
                        )
                    })
                    }
                    </form>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" type={"submit"} form={"subsidyForm"}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubsidyDialog;
