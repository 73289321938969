import React from 'react';
import {MixpanelTrack} from '../../../util/MixpanelHelper';
import ContainerHeader from '../util/ContainerHeader';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom';
import {isShopChainAction, getChainShopsAction} from '../../../actions/ManageShops';
import CardBox from '../analytics/util/CardBox';
import ShopItem from './ShopItem';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../util/IntlMessages";
import {NotificationManager} from "react-notifications";
import ValidateEmail from "../util/ValidateEmail";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {states} from "../ShopInfo/states";
import {TaxMask} from "../ShopInfo/MiscFields";
import PopoverItem from "../ShopInfo/PopoverItem";
import MaskedInput from "react-text-mask";
import OpenClosedSwitch from "../ShopInfo/OpenClosedSwitch";
import {addShopToChain} from "../../../actions/ShopInfo";
import CircularProgress from "@material-ui/core/CircularProgress";

function PhoneMask(props) {
    const {mask, inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function AmMask(props) {
    const {mask, inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/\d+/, /\d/, ':', /[0-5]/, /\d/, ' ', 'a', 'm']}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function PmMask(props) {
    const {mask, inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/\d/, /\d/, ':', /[0-5]/, /\d/, ' ', 'p', 'm']}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

class ManageShops extends React.Component {
    constructor(props) {
        super(props)

        props.isShopChainAction(this.props.shopId,
            (resp) => {
                if (resp != "True") {
                    this.props.history.push('/app/overview')
                }
            })

        this.props.getChainShopsAction(this.props.shopId)
        this.state = {
            addDialogOpen: false,
            successDialogOpen: false,
            saving: false,
            shopName: "",
            email: "",
            password: "",
            address: "",
            phoneNumber: "",
            city: "",
            state: "",
            zipCode: "",
            tax: "",
            hours: [
                {
                    day: "Sun",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Mon",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Tues",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Wed",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Thurs",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Fri",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Sat",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                }
            ]
        }
        this.formRef = React.createRef();
        this.onAddSuccess = this.onAddSuccess.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.addShop = this.addShop.bind(this);
    }

    componentDidMount() {
        MixpanelTrack("Visited Mange Shops Page")
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleHoursChange = (index, key) => event => {
        var copy = Object.assign(this.state.hours, [])
        copy[index][key] = event.target.value
        this.setState({
            hours: copy
        })
    }

    clearForm() {
        this.setState({
            addDialogOpen: false,
            saving: false,
            shopName: "",
            email: "",
            password: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            phoneNumber: "",
            tax: "",
            hours: [
                {
                    day: "Sun",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Mon",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Tues",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Wed",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Thurs",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Fri",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Sat",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                }
            ]
        })
    }

    handleOpenCloseChange = (index, value) => {
        var copy = Object.assign(this.state.hours, [])
        copy[index]['isOpen'] = value
        this.setState({
            hours: copy
        })
        MixpanelTrack('Set day open to ' + value)
    }

    checkSignupValid() {
        const {shopName, email, password, address, city, state, zipCode, tax, phoneNumber} = this.state;
        if (shopName === "" ||
            email === "" ||
            password === "" ||
            address === "" ||
            city === "" ||
            state === "" ||
            zipCode === "" ||
            phoneNumber === "" ||
            tax === "") {
                NotificationManager.error("All fields must be filled", "Error", 6000)
                return false
        }
        const validateEmail = new ValidateEmail()
        if (!validateEmail.isValid(this.state.email)) {
            NotificationManager.error("Email is not valid", "Error", 6000)
            return false
        }
        return true
    }

    onAddSuccess= async () => {
        this.clearForm()
        this.setState({addDialogOpen: false, successDialogOpen: true});
        await this.props.getChainShopsAction(this.props.shopId);
    }

    onAddError(error) {
        NotificationManager.error(error, "Error Adding Shop", 6000);
    }

    addShop = async () => {
        if(this.checkSignupValid()) {
            await this.setState({saving:true});
            const body = {
                hours: this.state.hours,
                chainShopId: this.props.shopId,
                loginEmail: this.state.email,
                password: this.state.password,
                shopName: this.state.shopName,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zipCode: this.state.zipCode,
                tax: (typeof this.state.tax === 'string')? parseFloat(this.state.tax.replace('%', '')) : this.state.tax,
                phoneNumber: this.state.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g,''),
            }
            await this.props.addShopToChain(body, this.onAddSuccess, (error) => error.json().then(response => this.onAddError(response.error)));
            this.setState({saving: false});
        }
    }

    getSuccessDialog() {
        return (
            <Dialog
                open={this.state.successDialogOpen}
                onClose={() => this.setState({successDialogOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Success!"}</DialogTitle>
                <DialogContent>
                    <div className="app-login-form">
                        <span>Your new shop has been created in our marketplace, but it is not currently live.
                            To make your new shop live in the app, please reach out to <a href={'mailto:support@coffeepass.io'}>support@coffeepass.io</a></span>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({successDialogOpen: false})} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const shopItems = this.props.chainShops.map(shop => {
            return <ShopItem {...shop} />
        })

        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Manage Shops"} match={this.props.match}/>
                <div style={{margin: 20}}>
                    <CardBox styleName="col-lg-12" heading={"Shops"}>
                        <div style={{display: "flex", justifyContent: "flex-end"}}><Button variant={"contained"}
                                                                                           color={"primary"}
                                                                                           disabled={this.state.saving}
                                                                                           onClick={() => this.setState({addDialogOpen: true})}>
                            + Add Shop</Button></div>
                        {shopItems}
                        <div/>
                    </CardBox>
                </div>
                <Dialog
                    open={this.state.addDialogOpen}
                    onClose={() => this.setState({addDialogOpen: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Add a shop to the chain"}</DialogTitle>
                    <DialogContent>
                        <div className="app-login-form">
                            <form method="post" ref={this.formRef} action="/">
                                <div style={{width: '100%'}}>
                                    <CardBox cardStyle={{height: '100%'}} styleName="col-lg-12" heading={"Shop Login"}>
                                        <>
                                            <div className="col-md-15 col-15">
                                                <TextField
                                                    type="text"
                                                    label="Shop Name"
                                                    onChange={this.handleChange('shopName')}
                                                    fullWidth
                                                    defaultValue={this.state.shopName}
                                                    margin="normal"
                                                    className="mt-0 mb-2"
                                                />
                                            </div>
                                            <div className="col-md-15 col-15">
                                                <TextField
                                                    type="email"
                                                    onChange={this.handleChange('email')}
                                                    label='Shop Login Email'
                                                    fullWidth
                                                    defaultValue={this.state.email}
                                                    margin="normal"
                                                    className="mt-0 mb-2"
                                                />
                                            </div>
                                            <div className="col-md-15 col-15">
                                                <TextField
                                                    type="Password"
                                                    onChange={this.handleChange('password')}
                                                    label={<IntlMessages id="appModule.password"/>}
                                                    fullWidth
                                                    defaultValue={this.state.password}
                                                    margin="normal"
                                                    className="mt-0 mb-4"
                                                />
                                            </div>
                                        </>
                                    </CardBox>
                                    <CardBox cardStyle={{height: '100%'}} styleName="col-lg-12" heading={"Shop Info"}>
                                        <>
                                            <div className="col-md-15 col-15">
                                                <FormControl>
                                                    <InputLabel>Shop Phone Number *</InputLabel>
                                                    <Input
                                                        required
                                                        value={this.state.phoneNumber}
                                                        onChange={this.handleChange('phoneNumber')}
                                                        inputComponent={PhoneMask}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-15 col-15">
                                                <TextField
                                                    required
                                                    id="address"
                                                    label="Address"
                                                    value={this.state.address}
                                                    onChange={this.handleChange('address')}
                                                    defaultValue=""
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-md-15 col-15">
                                                <TextField
                                                    required
                                                    id="city"
                                                    label="City"
                                                    value={this.state.city}
                                                    onChange={this.handleChange('city')}
                                                    defaultValue=""
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-md-15 col-15">
                                                <FormControl margin="normal">
                                                    <InputLabel>State *</InputLabel>
                                                    <Select
                                                        value={this.state.state}
                                                        onChange={this.handleChange('state')}
                                                        required
                                                        inputProps={{
                                                            name: 'state'
                                                        }}>
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {states.map(obj => {
                                                            return (
                                                                <MenuItem value={obj.abbreviation}>{obj.name}</MenuItem>)
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-md-15 col-15">
                                                <TextField
                                                    required
                                                    id="zipCode"
                                                    label="Zip"
                                                    value={this.state.zipCode}
                                                    onChange={this.handleChange('zipCode')}
                                                    defaultValue=""
                                                    margin="normal"
                                                    fullWidth
                                                    type='number'
                                                />
                                            </div>
                                        </>
                                    </CardBox>
                                    <CardBox styleName="col-lg-12" heading={"Tax"}>
                                        <>
                                            <div className="col-15">
                                                <TextField
                                                    required
                                                    label="Tax"
                                                    value={this.state.tax}
                                                    onChange={this.handleChange('tax')}
                                                    margin="normal"
                                                    InputProps={{
                                                        inputComponent: TaxMask,
                                                    }}
                                                />
                                                <PopoverItem
                                                    placement={'right'}
                                                    id={'myinfoId1'}
                                                    title="What is This?"
                                                    text="Some states require tax for every drink/food transaction. Put the percentage of the transaction that is taxed here. If your state doesn't require tax, leave this as 0%."/>
                                            </div>
                                        </>
                                    </CardBox>
                                    <CardBox styleName="col-lg-12" heading={"Hours"}>
                                        <>
                                            {this.state.hours.map((day, index) => {
                                                return (
                                                    <FormControl style={styles.form} key={index}>
                                                        <medium style={styles.dayText}> {day.day}:</medium>
                                                        <Input
                                                            style={styles.input}
                                                            value={day.openTime}
                                                            onChange={this.handleHoursChange(index, 'openTime')}
                                                            inputComponent={AmMask}
                                                            disabled={!day.isOpen}
                                                        />
                                                        <medium style={styles.toText}>to</medium>
                                                        <Input
                                                            style={styles.input}
                                                            value={day.closeTime}
                                                            onChange={this.handleHoursChange(index, 'closeTime')}
                                                            inputComponent={PmMask}
                                                            disabled={!day.isOpen}
                                                        />
                                                        <OpenClosedSwitch switch={day.isOpen}
                                                                          onChange={(checked) => this.handleOpenCloseChange(index, checked)}/>
                                                    </FormControl>
                                                )
                                            })}
                                        </>
                                    </CardBox>
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.saving} onClick={() => this.clearForm()}
                                color="primary">
                            Cancel
                        </Button>
                        {this.state.saving ? <div style={styles.progress} >
                            <CircularProgress size={50} />
                        </div> : <Button disabled={this.state.saving} onClick={() => this.addShop()} color="primary">Add Shop

                        </Button>}
                    </DialogActions>
                </Dialog>
                {this.getSuccessDialog()}
            </div>
        )
    }
}

const styles = {
    dayText: {
        alignSelf: 'center',
        width: 40
    },
    toText: {
        alignSelf: 'center'
    },
    form: {
        flexDirection: 'row',
        marginBottom: 10
    },
    input: {
        marginLeft: 20,
        marginRight: 20,
        width: 75
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    progress: {
        display: 'flex',
        margin: 200,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
}

const mapStateToProps = (state) => {
    return {
        shopId: state.auth.shop.shopId,
        chainShops: state.manageShops.shops
    }
}

const actionCreators = {
    isShopChainAction,
    getChainShopsAction,
    addShopToChain
}

export default withRouter(connect(mapStateToProps, actionCreators)(ManageShops))
